<template>
  <div class="container" style="height: 100%">
    <div class="img">
      <img src="img/no_img.jpeg" alt="" />
    </div>
    <div class="text">
      <h2>您的访问受限！</h2>
      <p>
        服务器拒绝处理您的请求！您可能没有访问此操作的权限,请联系管理给您权限设置。
      </p>
    </div>
    <!-- <p class="mega">4<span class="boom">0</span>4</p>
    <div class="bola" ref="container"></div>
    <p class="mini">That's an error.</p> -->
  </div>
</template>

// <script src="/js/three.js"></script>

<script>
export default {
  name: "Com404",
  methods: {
    initThree() {
      return
      var $container = this.$refs.container;
      var renderer = new THREE.WebGLRenderer({ antialias: true });
      var camera = new THREE.PerspectiveCamera(80, 1, 0.1, 10000);
      var scene = new THREE.Scene();

      scene.add(camera);
      renderer.setSize(300, 300);
      $container.append(renderer.domElement);

      // Camera
      camera.position.z = 200;

      // Material
      var pinkMat = new THREE.MeshPhongMaterial({
        color: new THREE.Color("rgb(226,35,213)"),
        emissive: new THREE.Color("rgb(0,0,0)"),
        specular: new THREE.Color("rgb(255,155,255)"),
        shininess: 100,
        shading: THREE.FlatShading,
        transparent: 1,
        opacity: 1,
      });

      var L1 = new THREE.PointLight(0xffffff, 1);
      L1.position.z = 100;
      L1.position.y = 100;
      L1.position.x = 100;
      scene.add(L1);

      var L2 = new THREE.PointLight(0xffffff, 0.8);
      L2.position.z = 200;
      L2.position.y = 400;
      L2.position.x = -100;
      scene.add(L2);

      // IcoSphere -> THREE.IcosahedronGeometry(80, 1) 1-4
      var Ico = new THREE.Mesh(new THREE.IcosahedronGeometry(75, 1), pinkMat);
      Ico.rotation.z = 0.5;
      scene.add(Ico);
      
      this.render();
    },
    update() {
      Ico.rotation.x += 2 / 100;
      Ico.rotation.y += 2 / 100;
    },

    render() {
      requestAnimationFrame(render);
      renderer.render(scene, camera);
      update();
    }
  },
  activated() {
    if (typeof THREE !== "undefined") {
      this.initThree();
    } else {
      // Load Three.js script dynamically
      const script = document.createElement("script");
      script.src = "/js/three.js"; // Adjust the path based on your project structure
      script.onload = () => {
        this.initThree();
      };
      document.head.appendChild(script);
    }
    return;
  },
};
</script>

<style lang="less" scoped>
.container,
body,
html {
  height: 100%;
}
.container {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  padding: 4% 2%;
  box-sizing: border-box;
}
.img {
  width: 100%;
  padding-top: 4%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.img img {
  width: 100%;
  height: auto;
}
@media screen and (min-width: 450px) {
  .img img {
    width: 400px;
  }
}
@media screen and (max-width: 450px) {
  .img {
    width: 200px;
  }
}
.img img {
  height: 100%;
}
.text {
  text-align: center;
}

//
body {
  background: #f5f5f5;
  font-family: "Roboto", sans-serif;
}

.mini {
  font-size: 1em;
  color: #000;
  line-height: 9em;
  text-indent: 2.5em;
  position: absolute;
  left: 50%;
  top: 50%;
  font-family: "Roboto", sans-serif;
}
.mega,
.bola {
  line-height: 1.65em;
  font-weight: bold;
  font-size: 11em;
  color: black;
  font-family: "Roboto", sans-serif;
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px;
}

.boom {
  color: #f5f5f5;
}
</style>
