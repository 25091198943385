<template>
  <div class="card" v-show="data.isx_t">
    <!-- 看板排序数字输入框 -->
    <div class="card_input">
      <el-input class="card_xnum card_isxnum" placeholder="请输入内容" v-model.number="data.isx_num" @change="change_isx_num" type="text" step v-show="data.isx_t"></el-input>
    </div>
    <!-- 多少号链接 -->
    <div class="card_input">
      <el-input class="card_link_num" placeholder="请输入内容" v-model.number="data.link_num" @change="change_link_num" type="text" step></el-input>
    </div>
    <div class="jiangjie">{{ formattedTime }}</div>
    
    <!-- 是否设置到看板 -->
    <div class="item">
      <div class="card_close" @click="up_isx">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <!-- 图片 -->
    <el-image class="card_img" style="max-height: 50px; height: 50px; width: 100%" :src='data.url?data.url + ".jpg":"https://pic.rmb.bdstatic.com/bjh/5eba9634898886c5650982b3d0ef22ab.png"' v-on:dblclick="up_isx($event)"></el-image>
    <!-- ID -->
    <div v-if="data.commodity_id" class="commodity_id">
      {{data.commodity_id}} <div  class="icon el-icon-copy-document" v-clipboard="data.commodity_id" v-clipboard:success="onCopySuccess"  v-clipboard:error="onCopyError"></div>
    </div>
    <div class="text_i basic" v-html="data.basic" style="white-space: pre-wrap"></div>
    <div class="text_i" v-html="data.text_jiu" style="white-space: pre-wrap"></div>
    <div class="side_btn flex">
      <!-- <el-button @click="get_data_one" size="small">刷新</el-button> -->
      <el-button @click="get_repeat" size="mini">开始</el-button>
      <el-button size="mini" @click="toggleDouyinDataFetch">{{ isFetching ? '重复中' : '重复' }}</el-button>
      <el-button @click="get_douyin_one" size="mini">获取</el-button>
      <!-- <i :class="{'el-icon-bell': data.counttime !== 1, 'el-icon-message-solid': data.counttime === 1}"></i> -->
      <el-button size="mini" @click="up_counttime">讲解</el-button>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'SideCard',
  data () {
    return {
      intervalId: null,
      isFetching: false,
      seconds: 0,
      minutes: 0,
      intervalId1: null
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          url: "https://pic.rmb.bdstatic.com/bjh/5eba9634898886c5650982b3d0ef22ab.png",
          text: "内容"
        }
      }
    }
  },
  computed: {
    formattedTime() {
      return `${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}`
    }
  },
  watch: {
    'data.counttime': function (newVal, oldVal) {
      if(newVal === 0){
        this.stopCountdown();
      } else if (newVal === 1) {
        this.startCountdown();
      }
    }
  },
  methods: {
    // 重复并获取
    async get_repeat(){
      await this.get_douyin_one();
      await this.toggleDouyinDataFetch();
    },
    // 设置开始倒计时
    up_counttime(){
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/counttime',
        data: {
          id: this.data.id
        }
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("counttime",{id:this.data.id,counttime:this.data.counttime,isx:this.data.isx});
        }
      })
    },
    // 倒计时
    startCountdown() {
      this.stopCountdown();
      this.seconds = 0;
      this.minutes = 0;
      this.intervalId1 = setInterval(() => {
        this.seconds++;
        if (this.seconds >= 60) {
          this.minutes++;
          this.seconds = 0;
        }
      }, 1000);
    },
    stopCountdown() {
      if (this.intervalId1) {
        clearInterval(this.intervalId1);
        this.intervalId1 = null;
      }
    },
    /** */
    // 获取抖音数据
    get_douyin_one() {
      if (this.data.bannum.some(item => item.skuidkucun && item.skuidkucun.trim() !== '')) {
          this.$socket.emit("get_douyin_one", {data: this.data, id: this.data.id});
      }else{
        this.$message.error("发送错误,检查是否设置skuid");
      }
    },
    //重复获取
    toggleDouyinDataFetch() {
      if(this.data.counttime==0){
        this.up_counttime();
      }
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.isFetching = false;
      } else {
        this.intervalId = setInterval(this.get_douyin_one, 1000);
        this.isFetching = true;
      }
    },
    onCopySuccess() {
    this.$message.success("复制成功");
    },
    onCopyError() {
      this.$message.error("复制失败");
    },
    mouseOver_hide_id (data) {
    },
    mouseLeave_hide_id (data) {
    },
    get_data_one(){
      this.$socket.emit("get_data_one",this.data.id);
    },
    // 修改是否到看板
    up_isx (e) {
      if(e.ctrlKey){
        this.$emit('GetDataSearch',this.data.commodity_id);
        return;
      }
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_data_isx',
        data: {
          id: this.data.id, isx: this.data.isx
        }
      }).then((res) => {
        if (res.data.code == 1 || res.data==1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("up_isx");
        }
      })
    },
    // 设置多少号链接
    change_link_num () {
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_data_link_num',
        data: {
          id: this.data.id, link_num: this.data.link_num
        }
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("up_isx");
        }
      })
    },
    // 设置排序
    change_isx_num () {
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_data_isx_num',
        data: {
        id: this.data.id, isx_num: this.data.isx_num==0?1:this.data.isx_num
        }
      }).then((res) => {
        if (res.data.state == 400) {
          this.$message({
            message: '错误',
            type: 'error',
            duration: 1000
          });
          this.data.isx_num = res.data.isx_num;
        }
        if (res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("up_isx");
        }
      })
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }
    this.stopCountdown();
  }
}
</script>

<style scoped>
.card{border-radius:10px;background:#fff;margin-bottom:20px;padding:6px;width:260px;box-shadow:rgb(3 14 44 / 9%) 0px 2px 6px 0px;position:relative;margin:0 10px 20px 10px;/* break-inside:avoid-column;*/
}
.card_close{z-index:2;position:absolute;cursor:url(/public/img/cursor/hand.png) 0 0 ,auto;;right:8px;width:20px;text-align:center;}
.card_xnum,.card_link_num{position:absolute;top:8px;left:8px;z-index:2;height:24px;width:40px;}
.card_link_num{left:58px;}
.card_isx{position:absolute;top:8px;right:8px;z-index:2;height:24px;width:40px;}
:deep(.card_input input){
  border:1px solid #d5d6de;height:24px;padding-left:8px;padding-right:8px;font-size:12px;
}
:deep(.card_input input:hover){
  background-color:#fff;border-color:#6557fa;border-right-width:1px !important;
}
/* .card_img img{border-radius:4px;cursor:url(/public/img/cursor/hand.png) 0 0 ,auto;;} */
.img{height:200px;}
:deep(.card_img img){max-width:100%;max-height:100%;width:auto;height:auto;position:absolute;left:50%;top:50%;transform:translate3d(-50%,-50%,0);border-radius:4px; cursor: url(/public/img/cursor/hand.png) 0 0 ,auto;}
.commodity_id{display:flex; justify-content: center; width:100%; color:#131520; font-size:12px;}
.commodity_id .icon{font-size:16px; margin:0 5px; cursor: url(/public/img/cursor/hand.png) 0 0 ,auto; color:#a9a9a9}
.jiangjie{position:absolute;top:34px;left:8px;z-index:2;height:24px;width:40px;}
</style>

<style>
/* .card .text_i i{color:#fd2f2f; font-style: normal;} */
.card .text_i i{color:#fd2f2f; font-style: normal; color: #FF5000; border-color: #FAB596; background: #FFFFFF; display: inline-block;padding: 0 10px;border: 2px solid #ff5000;border-radius: 4px; margin-bottom:4px; margin:6px 0 4px 4px;}
.card .text_i i{color:#fff; background:#fe7578; border:none; margin:6px 0 6px 4px;}
.card .text_i b{background:#f8bf6a; font-weight: normal; padding:4px 4px; border-radius:4px; display: inline-block; margin-bottom:4px; }
/* .card .text_i u{background:#EBE1F7; text-decoration:none; color:#4D3161; padding:4px 4px; border-radius:4px; display: inline-block; margin-bottom:4px; } */
</style>