<template>
    <div class="container container_cardTake">
        <div class="img-container">
            <img :src="imageUrl" />
        </div>
        <div>{{cardData.commodity_id}}</div>
        <div v-show="cardData.basic" v-html="cardData.basic" class="pre-line text_i"></div>
        <div v-show="cardData.text" v-html="cardData.text" class="pre-line"></div>
        <div class="table" v-show="cardData.bannum.length>0">
            <div class="row table_header">
                <div>颜色</div>
                <div>下单数量</div>
                <div>支付数量</div>
                <div>剩余</div>
            </div>
            <div class="row" v-show="cardData.bannum && cardData.bannum.length > 0" :class="{'isno': bannum.isno === 0}" v-for="bannum in cardData.bannum" :key="bannum.bannumid">
                <div>{{bannum.color}}</div>
                <div>{{bannum.csnum}}</div>
                <div>{{bannum.paynum}}</div>
                <div>{{bannum.surnum}}</div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data(){
        return {
            imageUrl: this.cardData.url ? this.cardData.url : 'https://p3-aio.ecombdimg.com/obj/ecom-shop-material/kpJWVrPA_m_ca1593adc6fb61a8ec3dfc7c87c5556c_sx_20124_www533-300',
        }
    },
    props: {
        cardData: {
            type: Object,
            default: () => {
                return {
                    url:"",
                    basic:"",
                    text:"",
                    commodity_id:"",
                    bannums:[],
                    bannum:[]
                }
            }
        },
    },
    methods:{
        getBase64Image(imgUrl) {
            let img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = imgUrl;
            img.onload = () => {
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                canvas.height = img.naturalHeight;
                canvas.width = img.naturalWidth;
                ctx.drawImage(img, 0, 0);
                let dataURL = canvas.toDataURL('image/png');
                this.imageUrl = dataURL;
            };
        }
    },
    watch: {
        'cardData.url': {
            immediate: true,
            async handler(newUrl) {
                if (newUrl.includes('ecombdimg.com')) {
                    await this.$axios({
                        method: "post",
                        url: '/api/kanban/image-to-base64',
                        data: {
                            imageUrl: newUrl
                        },
                    }).then((res) => {
                        if (res.data) {
                            this.imageUrl = res.data;
                        } else {
                            this.$message({
                                message: '图片转换失败',
                                type: 'error',
                                duration: 1000
                            });
                        this.imageUrl = 'https://p3-aio.ecombdimg.com/obj/ecom-shop-material/kpJWVrPA_m_ca1593adc6fb61a8ec3dfc7c87c5556c_sx_20124_www533-300';
                        }
                    }).catch((error) => {
                        console.error(error);
                        this.imageUrl = 'https://p3-aio.ecombdimg.com/obj/ecom-shop-material/kpJWVrPA_m_ca1593adc6fb61a8ec3dfc7c87c5556c_sx_20124_www533-300';
                    });
                } else {
                    this.getBase64Image(newUrl);
                }
            }
        }
    },
}
</script>

<style scoped>
.container{display:flex;justify-content:center;align-items:center;flex-direction:column; padding-top:10px;}
.img-container{width:100px;height:100px;}
.img-container img{width:100%;height:100%;border-radius:4px;}
.img-container,.pre-line{margin-bottom:4px;padding:4px;border:1px solid #ddd;border-radius:4px;}
.pre-line{white-space:pre-line;width:200px;}
.div-color{color:red;}
.div-csnum{font-weight:bold;}
.pre-line i{color:red;}
.table{display:flex;flex-direction:column;width:100%;}
.row{display:flex;justify-content:space-between;border-bottom:1px solid #ddd;padding:10px 0;}
.row.table_header{font-weight:bold;border-top:1px solid #ddd;}
.row > div{flex:1;text-align:center;width:120px;}
.table .row:last-of-type{border:none;}
.isno {
    background-color: #f5f7fa;
}
</style>
<style>
.container_cardTake i{color:#fd2f2f; font-style: normal;}
</style>