import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 属性
    counter:0,
    user:{
      username:"",
      useravatar:"",
      userid:null,
      guanli:0
    }
  },
  getters: {
  },
  mutations: {
    // 方法
    ChangeState(state){
      // 自增1
      state.num++;
    },
    // 这是用户名
    UpdateUserName(state,value){
      this.state.user.username = value;
    },
    // 设置id
    UpdateUserId(state,value){
      this.state.user.userid = value;
    },
    // 设置用户头像user-avatar
    UpdateUserAvatar(state,value){
      this.state.user.useravatar = value;
    },
    // 这是用户管理id
    UpdateUserGuanli(state,value){
      this.state.user.guanli = value;
    },
    // 设置是否显示
    UpdateJia(state,value){
      this.state.user.jia = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
