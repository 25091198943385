<template>
  <div class="card">

    <!-- 左侧 -->
    <div class="gu_top">
      <!-- 图片 -->
      <div class="img">
        <el-image class="card_img" style="max-height: 100%; height: 100%; width: 100%" lazy :src="data.url">
          <div slot="error" class="image-slot">
            <i class="el-icon-circle-plus-outline"></i>
          </div>
        </el-image>
      </div>

      <!-- 输入框 -->
      <div class="text" v-loading="data.text_loading">
        <el-input type="textarea" class="text_i" :autosize="{ minRows: 1 }" placeholder="请输入内容" @change="change_fn" @blur="data.isNo = false" ref="text" v-model="data.text" v-show="!data.isNo"></el-input>
      </div>

      <!-- 固定按钮 -->
      <div class="gu_item">
        <!-- 是否设置到看板 -->
        <div class="item">
          <el-tooltip effect="dark" content="添加/取消 到看板" placement="top">
            <el-switch class="card_isx" @change="up_isx" v-model="data.isx_t" active-color="#13ce66"></el-switch>
          </el-tooltip>
        </div>
        <!-- 是否固定 -->
        <div class="item">
          <el-switch class="card_isx isg" @change="up_isg" v-model="data.isg_t" active-color="#f56c6c"></el-switch>
        </div>
      </div>
    </div>
    <!-- 修改图片 -->
    <el-dialog title="修改图片" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-image class="card_img" style="max-height: 200px; height: 200px; width: 100%" lazy :src="form.url">
          <div slot="error" class="image-slot">
            <i class="el-icon-circle-plus-outline"></i>
          </div>
        </el-image>
        <el-form-item label="图片地址" :label-width="formLabelWidth">
          <el-input v-model="form.url" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="click_from">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 表格数据 -->
    <div class="gu_table">
      <div v-if="data.bannum.length != 0" class="table">
        <el-table class="table_n" :data="data.bannum" border stripe style="width: 100%" height="150px">
          <el-table-column label="颜色" width="80">
            <template slot-scope="scope">
              <div class="table_name">
                <div class="table_name_btn">
                  <el-popconfirm :title=" scope.row.isno == 1 ? '确定隐藏此规格吗？' : '确定删除此规格吗？' " @confirm="del_bannum(scope.row.bannumid)">
                    <el-button slot="reference">
                      <el-button type="danger" icon="el-icon-delete" circle size="mini" ></el-button>
                    </el-button>
                    <el-button v-show="!scope.row.isno" class="table_name_btn_huifu" slot="reference">
                      <el-tooltip class="item" effect="dark" content="恢复" placement="top">
                        <el-button type="danger" icon="el-icon-refresh-right" @click="isXian(scope.row.bannumid)" circle size="mini"></el-button>
                      </el-tooltip>
                    </el-button>
                  </el-popconfirm>
                </div>
                <el-input :ref="'color' + scope.$index" @keyup.native="moveFocus($event, scope.$index, 'color')" @change.native="up_bannum($event, scope.row.bannumid)" :disabled="!scope.row.isno" name="color" v-model="scope.row.color" placeholder="颜色"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="40">
            <template slot-scope="scope">
              <el-input :ref="'money' + scope.$index" @keyup.native="moveFocus($event, scope.$index, 'money')" @change.native="up_bannum($event, scope.row.bannumid)" :disabled="!scope.row.isno" name="money" v-model="scope.row.money" placeholder="金额"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="下单" width="40">
            <template slot-scope="scope">
              <el-input :ref="'csnum' + scope.$index" @keyup.native="moveFocus($event, scope.$index, 'csnum')" @change.native="up_bannum($event, scope.row.bannumid)" :disabled="!scope.row.isno" name="csnum" v-model="scope.row.csnum" placeholder="下单" ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="仓库" width="40">
            <template slot-scope="scope">
              <el-input :ref="'cknum' + scope.$index" @keyup.native="moveFocus($event, scope.$index, 'cknum')" @change.native="up_bannum($event, scope.row.bannumid)" :disabled="!scope.row.isno" name="cknum" v-model="scope.row.cknum" placeholder="仓库"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="支付" width="40">
            <template slot-scope="scope">
              <el-input :ref="'paynum' + scope.$index" @keyup.native="moveFocus($event, scope.$index, 'paynum')" @change.native="up_bannum($event, scope.row.bannumid)" :disabled="!scope.row.isno" name="paynum" v-model="scope.row.paynum" placeholder="支付"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="剩余" width="50">
            <template slot-scope="scope">
              <div class="table_name">
                <div v-if="scope.row.isno" class="table_name_btn">
                  <el-button slot="reference">
                    <el-button icon="el-icon-remove" @click="surnum_fn(scope.row)" circle size="mini"></el-button>
                  </el-button>
                </div>
                <el-input :ref="'surnum' + scope.$index" @keyup.native="moveFocus($event, scope.$index, 'surnum')" @change.native="up_bannum($event, scope.row.bannumid)" :disabled="!scope.row.isno" name="surnum" v-model="scope.row.surnum" placeholder="剩余"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="已上" width="30">
            <template slot-scope="scope">
              <el-input :ref="'onnum' + scope.$index" @keyup.native="moveFocus($event, scope.$index, 'onnum')" @change.native="up_bannum($event, scope.row.bannumid)" :disabled="!scope.row.isno" name="onnum" v-model="scope.row.onnum" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column label="显示" width="30">
            <template slot-scope="scope">
              <el-input :ref="'disnum' + scope.$index" @keyup.native="moveFocus($event, scope.$index, 'disnum')" @change.native="up_bannum($event, scope.row.bannumid)" :disabled="!scope.row.isno" name="disnum" v-model="scope.row.disnum" placeholder=""></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      
      <!-- 新增表格数据按钮 -->
      <div class="btn_add">
        <el-tooltip effect="dark" content="添加规格" placement="top">
          <el-button @click="addBannum" size="small"><i class="el-icon-circle-plus-outline"></i> </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="设置排序" placement="top">
          <el-button v-if="data.bannum.length != 0" size="mini" @click="paixu.isno = true"><i class="el-icon-setting"></i> </el-button>
        </el-tooltip>
      </div>
    </div>

    
    <!-- 设置规格排序 -->
    <el-dialog title="设置排序" :visible.sync="paixu.isno" v-if="data.bannum.length>0" >
      <div v-for="(item,index) in data.bannum" :key="index">
        <el-input placeholder="请输入内容" v-model.number="item.paixu" @change="up_paixu(item)">
          <template slot="prepend">{{item.color}}</template>
        </el-input>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'CardGu',
  data () {
    return {
      dialogFormVisible: false,
      form: {
        url: ""
      },
      paixu:{
        isno:false
      },
      formLabelWidth: '120px'
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          url: "https://pic.rmb.bdstatic.com/bjh/5eba9634898886c5650982b3d0ef22ab.png",
          text: "内容"
        }
      }
    }
  },
  mounted () {
    
  },
  methods: {
    
    // 修改已上/显示数量
    up_num_clear(){
      return
    },
    // 表格键盘移动
    moveFocus(event, index, key){
      // 向上
      if (event.keyCode === 38) {
        if (index === 0) { // 第一行
          return
        }
        this.$refs[key + index].blur()
        this.$nextTick(() => {
          this.$refs[key + (index - 1)].focus()
        })
      }
      // 下 = 40
      if (event.keyCode === 40) {
        if(this.declItems==0){
          this.declItems = this.data.bannum.filter(item=>{
            return item.isno == 1;
          }).length;
        }
        if (index === this.declItems - 1) { // 最后一行
          return
        }
        this.$refs[key + index].blur()
        this.$nextTick(() => {
          this.$refs[key + (index + 1)].focus()
        })
      }

    },
    mouseOver_hide_id (data) {
    },
    mouseLeave_hide_id (data) {
    },
    // 修改是否到看板
    up_isx () {
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_data_isx',
        data: {
          id: this.data.id, isx: this.data.isx
        }
      }).then((res) => {
        if (res.data.code == 1 || res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("up_isx");
        }
      })
    },
    // 修改是否到固定
    up_isg () {
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_data_isg',
        data: {
          id: this.data.id, isg: this.data.isg
        }
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          // this.$socket.emit("up_data",this.data.id);
          this.$socket.emit("up_data_isg");
        }
      })
    },
    // 修改文本
    change_fn () {
      this.data.text_loading = true;
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_data',
        data: {
          id: this.data.id, text: this.data.text
        }
      }).then((res) => {
        if (res.data == 1) {
          this.data.text_loading = false;
          this.$forceUpdate();
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          if (this.data.isx == 1) {
            this.$socket.emit("ban");//发送看板提示更新
          }
          this.$socket.emit("up_data", this.data.id);
        }
      })
    },
    // 修改图片
    click_from () {
      this.dialogFormVisible = false;
      this.data.url = this.form.url;

      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_data_url',
        data: {
          id: this.data.id, url: this.data.url
        }
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("data");
        }
      })
    },
    // 点击了div
    click_text () {
      this.data.isNo = true;
      setTimeout(() => {
        this.$refs.text.focus()
      }, 1)
    },
    
    // 新增表格数据
    addBannum(){
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/add_bannum',
        data: {
          id: this.data.id
        }
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("up_guige_table_data",this.data.id);
        }
      })
    },
    // 表格数据修改
    up_bannum(event,bannumid){
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_bannum',
        data: {
          type:event.target.name,value:event.target.value,bannumid
        }
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("up_table_data",{type:event.target.name,value:event.target.value,bannumid});
        }
      })
    },
    //隐藏表格数据
    del_bannum(bannumid){
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/del_bannum',
        data: {
          bannumid
        }
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("up_guige_table_data",this.data.id);
        }
      })
    },
    // 显示表格数据
    isXian(bannumid){
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_bannum',
        data: {
          type:"isno",value:"1",bannumid
        }
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("up_guige_table_data",this.data.id);
        }
      })
    },
    // 计算
    surnum_fn(data){
      this.n = 0;
      if(!data.cknum){
        this.n = data.csnum-data.paynum
      }else{
        this.n = data.cknum-data.paynum
      }
      if(this.n == data.surnum){
        this.$message({
            message: '无须计算~',
            duration: 1000
          });
        return;
      }
      data.surnum = this.n;

      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_bannum',
        data: {
          type:"surnum",value:this.n,bannumid:data.bannumid
        }
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          // this.$socket.emit("up_num",this.data.id);
          this.$socket.emit("up_table_data",{type:"surnum",value:this.n,bannumid:data.bannumid});
        }
      })
    },
    // 修改表格排序
    up_paixu(data){
      this.$axios({
        method: "post", //请求方式
        url: '/api/kanban/up_bannum',
        data: {
          type:"paixu",value:data.paixu,bannumid:data.bannumid
        }
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          });
          this.$socket.emit("up_guige_table_data",this.data.id);
        }
      })
    }
  },
}
</script>

<style scoped>
.card{
  border-radius:10px;background:#fff;margin-bottom:20px;padding:10px;width:calc(50% - 20px);box-shadow:rgb(3 14 44 / 9%) 0px 2px 6px 0px;position:relative;margin:0 10px 20px 10px;align-content:flex-start;height:280px;box-sizing:border-box;scroll-snap-align:start;
}
.card::-webkit-scrollbar{width:0px;opacity:0;-webkit-overflow-scrolling:touch;}
.gu_top{display:flex;height:100px; margin-bottom:10px;}
.card_isx{position:absolute;top:8px;right:8px;z-index:2;height:24px;width:40px;}
.isg{top:34px;}
:deep(.card_isx input){border:1px solid #d5d6de;height:24px;padding-left:8px;padding-right:8px;font-size:12px;}
:deep(.card_isx input:hover){background-color:#fff;border-color:#6557fa;border-right-width:1px !important;}
.card_img{border-radius:4px;cursor: url(/public/img/cursor/hand.png) 0 0 ,auto;}
.img{height:100px;width:100px;background:rgba(3,14,44,0.1);border-radius:10px;margin-right:10px;padding:10px;box-sizing:border-box;}
:deep(.img img){border-radius:10px;}
.text{overflow-y:scroll;height:100px; flex:1; margin-right:42px;}
:deep(.text textarea),.text{box-sizing:border-box;min-height:40px;width:300px;border:1px solid rgba(3,14,44,0.04);resize:none;min-height:54px;font-size:12px;font-weight:500;color:rgba(3,14,44,0.85);border-radius:4px;transition:border 0.3s ease 0s;white-space:pre-wrap;font-family:'PingFangSC-Regular',STHeiti,'Hiragino Sans GB','Microsoft YaHei','Helvetica Neue',Helvetica,Arial,sans-serif;line-height:14px;box-sizing:border-box;}
:deep(.text textarea){padding:0;width:calc(100% - 2px);border-color:#ccc;padding:10px;box-sizing:border-box;}
.text::-webkit-scrollbar{width:6px;}
.text::-webkit-scrollbar-track{background:#f1f1f1;border-radius:4px;}
.text::-webkit-scrollbar-thumb{background:#c6c6c6;border-radius:4px;}
.el-textarea__inner{padding:0;}
.text_t{border-radius:4px;min-height:20px;}
.text_i:focus{border-color:#000;}
.text_i textarea{line-height:20px;}
:deep(.card_img img){max-width:100%;max-height:100%;width:auto;height:auto;position:absolute;left:50%;top:50%;transform:translate3d(-50%,-50%,0);border-radius:4px;}
:deep(.image-slot .img){text-align:center;height:50px;font-size:40px;}

.gu_table{
  display:flex;
}
.table{
  height:150px; flex:1;
}
:deep(.table .el-table__body-wrapper::-webkit-scrollbar){width:6px;}
:deep(.table .el-table__body-wrapper::-webkit-scrollbar-track){background:#f1f1f1;border-radius:4px;}
:deep(.table .el-table__body-wrapper::-webkit-scrollbar-thumb){background:#c6c6c6;border-radius:4px;}
:deep(.card_xnum input),:deep(.table input){border:1px solid #d5d6de;height:24px;padding-left:8px;padding-right:8px;font-size:12px}
:deep(.card_xnum input),:deep(.table input:hover){background-color:#fff;border-color:#6557fa;border-right-width:1px !important}
:deep(.table_n input){font-size:16px;border:none;padding:0 2px;text-align:center}
:deep(.table_n .table_name input){padding:4px 2px 4px 18px}
:deep(.table_n .el-table__row .cell){padding:0!important}
.btn_add{display:flex; flex-wrap: wrap; justify-content:center;width:100%; width:40px; justify-content: center; margin-left:10px;}
:deep(.btn_add button){
  width:100%; padding:0; height:30px; line-height:30px; margin:0;
}
:deep(.el-table .cell){text-overflow:clip}
:deep(.table_n .has-gutter tr th){
  padding:0;
}
:deep(.table_n .has-gutter tr th div){padding:0;text-align:center}
.table_name{position:relative}
.table_name_btn{position:absolute;top:0;left:2px;width:16px;height:16px;z-index:2}
:deep(.table tr td),:deep(.table tr th){padding:0}
:deep(.table_name button){width:16px;height:16px;z-index:2;padding:0}
.table_name_btn_huifu{position:absolute;top:4px;left:10px}

</style>
