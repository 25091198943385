import axios from 'axios';
import { Loading } from 'element-ui';
var t1;
var t2;
export async function isNewVersion() {
    var randomNumber = Math.random()
    const url = `//${window.location.host}/version.json?id=${randomNumber}`;
    const res = await axios.get(url);
    const version = res.data.version;
    const localVersion = localStorage.getItem('version');
    if (localVersion && localVersion != version) {
        localStorage.setItem('version', version);
        const hide = () => {
            //我这里使用的是
            let loadingInstance = Loading.service({ fullscreen: true, text: '版本正在更新渲染加载中...' });
            clearTimeout(t1)
        }
        t1 = setTimeout(hide, 2000)
        t2 = setTimeout(() => {
            clearTimeout(t2)
            window.location.reload();
        }, 3000)
    } else {
        localStorage.setItem('version', version);
    }
}