import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/element.js'
// import SocketIO from "socket.io-client"
// import VueSocketIO from 'vue-socket.io'
import VueLazyload from 'vue-lazyload'
import Clipboard from 'v-clipboard'
import "../public/styles/iconfont/iconfont.css"
import 'default-passive-events'
import '../public/styles/public.css';//公共样式
import commonApi from '../public/js/commonApi.js';//公共方法
Vue.prototype.commonApi = commonApi;
import axiosInstance from './utils/axiosInstance' // 导入axiosInstance
Vue.prototype.$axios = axiosInstance;

Vue.config.productionTip = false;

let url = `https://yckanban.jackafan.top/`;
if(process.env.NODE_ENV=="development"){
  url = `http://localhost:3000/`;
}else if (process.env.NODE_ENV == "production") {
  //生产环境
}else{
  //本地环境
}


// Vue.use(new VueSocketIO({
//   debug: false,
//   connection: SocketIO(url,{
//     query: {
//       token: 11
//     }
//   }),
//   vuex: { // 不需要用到vuex这个可以不加
//     store,
//     actionPrefix: 'SOCKET_',
//     mutationPrefix: 'SOCKET_'
//   }
// }))

// const initialPath = window.location.pathname;
// const socket = SocketIO(url, {
//   query: {
//     token: 11,
//     path:initialPath
//   }
// });

// socket.on('error', (error) => {
//   // 阻止错误信息输出到控制台
//   console.error(error.message);
// });

// // 将socket实例添加到Vue原型上
// Vue.prototype.$socket = socket;

Vue.use(Clipboard);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error:require("../public/img/weigui.png"),
  loading:require("../public/img/file.gif"),
  attempt: 1
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')