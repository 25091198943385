<template>
  <div>
    <div class="card">
      <!-- 排序按钮 -->
      <el-input
        v-show="ActionsDis"
        class="card_xnum"
        placeholder="请输入内容"
        v-model.number="data.xnum"
        @change="change_xnum"
        type="text"
        step
      ></el-input>

      <!-- 链接按钮 -->
      <el-input
        v-show="ActionsDis"
        class="card_xnum card_xnum_url"
        placeholder="请输入内容"
        v-model.number="data.link_num"
        @change="change_link_num"
        type="text"
        step
      ></el-input>

      <div
        :class="{ binimg: BinImg }"
        class="img"
        :style="{ height: BinImg_num + 'px' }"
      >
        <img
          class="card_img"
          v-lazy="
            data.url
              ? data.url + '.jpg'
              : 'https://pic.rmb.bdstatic.com/bjh/5eba9634898886c5650982b3d0ef22ab.png'
          "
          v-on:dblclick="dbclick_img"
          @click="click_img"
          alt=""
        />
        <el-image-viewer
          v-if="showViewer"
          :on-close="closeViewer"
          :url-list="[data.url + '.jpg']"
        />
      </div>
      <!-- ID 复制ico-->
      <div v-show="ActionsDis" class="commodity_id">
        <div class="icon el-icon-edit commodity_id_move_down" @click="triggerUpdateId"></div>
        <div v-if="data.commodity_id">
          <el-tag type="info" size="mini">
            <span class="id-container">{{ data.commodity_id }}</span>
          </el-tag>
        </div>
        <div
          v-if="data.commodity_id"
          class="icon el-icon-copy-document commodity_id_move_down"
          v-clipboard="data.commodity_id"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
        ></div>
      </div>

      <!-- 是否设置到看板 按钮 -->
      <div class="item">
        <el-switch
          class="card_isx"
          @change="up_isx"
          v-model="data.isx_t"
          active-color="#13ce66"
        ></el-switch>
      </div>
      <!-- 是否固定 按钮 -->
      <div v-show="ActionsDis" class="item">
        <el-switch
          class="card_isx isg"
          @change="up_isg"
          v-model="data.isg_t"
          active-color="#f56c6c"
        ></el-switch>
      </div>
      <!-- 是否提示 按钮 -->
      <div v-show="ActionsDis" class="item">
        <el-switch
          class="card_isx isbadge"
          @change="up_isbadge"
          v-model="data.isbadge_t"
          active-color="#f56c6c"
        ></el-switch>
      </div>

      <!-- 基本信息 -->
      <div v-show="ActionsDis" class="text" v-loading="data.text_loading1">
        <el-input
          type="textarea"
          class="text_i"
          :autosize="{ minRows: 1 }"
          placeholder="基本信息"
          @change="change_basic_fn"
          @blur="data.isNo = false"
          v-model="data.basic"
          style="border: none"
        ></el-input>
      </div>

      <!-- 输入框 -->
      <div v-show="ActionsDis" class="text" v-loading="data.text_loading">
        <el-input
          type="textarea"
          class="text_i"
          :autosize="{ minRows: 1 }"
          placeholder="请输入内容"
          @change="change_fn"
          @blur="data.isNo = false"
          v-model="data.text"
          style="border: none"
        ></el-input>
      </div>
      <!-- 快速添加 -->
      <div class="fast_text_btn">
        <el-button
          v-if="data.bannum.length != 0"
          @click="get_douyin_one"
          size="small"
          >抖音库存</el-button
        >
        <el-button
          v-if="data.bannum.length != 0"
          size="small"
          @click="synchronizeInventory"
          :type="buttonType"
          >ERP库存</el-button
        >
        <!-- <i class="el-icon-refresh"></i> -->
        <div class="fast_text_div_g1">
          <div @click="red_up_text" class="cur fast_text_btn_red"></div>
          <div @click="y_up_text" class="cur fast_text_btn_bag"></div>
          <div
            @click="$emit('capture', data.id)"
            class="cur icon el-icon-picture-outline"
          ></div>
          <!-- v-clipboard="data.commodity_id" -->
          <!-- <div
            class="cur icon el-icon-copy-document"
            @click="copy_sku_list"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
          ></div> -->
        </div>
        <!-- <el-button @click="red_up_text" size="small">红色</el-button>
    <el-button @click="y_up_text" size="small">背景</el-button>
    <el-button @click="$emit('capture', data.id)" size="small">截图</el-button> -->
      </div>

      <div v-show="ActionsDis" v-if="data.bannum.length != 0" class="table">
        <div class="table-row header-row">
          <div class="table-cell" style="width: 98px">颜色</div>
          <div class="table-cell" style="width: 25px">额</div>
          <div class="table-cell" style="width: 25px">下</div>
          <div class="table-cell" style="width: 25px">仓</div>
          <div class="table-cell" style="width: 25px">付</div>
          <div class="table-cell" style="width: 60px">剩余</div>
          <div class="table-cell" style="width: 25px">上</div>
          <div class="table-cell" style="width: 25px">显</div>
          <div class="table-cell" style="width: 20px">未</div>
          <div class="table-cell" style="width: 21px">红</div>
        </div>
        <div
          class="table-row"
          v-for="(row, index) in data.bannum"
          :key="row.bannumid"
        >
          <!-- 颜色 Column -->
          <div class="table-cell" style="width: 98px">
            <div class="table_name color">
              <div class="table_name_btn">
                <el-popconfirm
                  :title="
                    row.isno == 1 ? '确定隐藏此规格吗？' : '确定删除此规格吗？'
                  "
                  @confirm="del_bannum(row.bannumid)"
                >
                  <el-button style="border: none" slot="reference">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      size="mini"
                    ></el-button>
                  </el-button>
                  <el-button
                    v-show="!row.isno"
                    class="table_name_btn_huifu"
                    slot="reference"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="恢复"
                      placement="right"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-refresh-right"
                        @click="isXian(row.bannumid)"
                        circle
                        size="mini"
                      ></el-button>
                    </el-tooltip>
                  </el-button>
                </el-popconfirm>
              </div>
              <el-input
                :ref="'color' + index"
                @keyup.native="moveFocus($event, index, 'color')"
                @change.native="up_bannum($event, row.bannumid)"
                :disabled="!row.isno"
                name="color"
                v-model="row.color"
                placeholder="颜色"
              ></el-input>
            </div>
          </div>
          <!-- 额 Column -->
          <div class="table-cell" style="width: 25px">
            <el-input
              :ref="'money' + index"
              @keyup.native="moveFocus($event, index, 'money')"
              @change.native="up_bannum($event, row.bannumid)"
              :disabled="!row.isno"
              name="money"
              v-model="row.money"
              placeholder="额"
            ></el-input>
          </div>
          <!-- 下 Column -->
          <div class="table-cell" style="width: 25px">
            <el-input
              :ref="'csnum' + index"
              @keyup.native="moveFocus($event, index, 'csnum')"
              @change.native="up_bannum($event, row.bannumid)"
              :disabled="!row.isno"
              name="csnum"
              v-model="row.csnum"
              placeholder="下单"
            ></el-input>
          </div>
          <!-- 仓 Column -->
          <div class="table-cell" style="width: 25px">
            <el-input
              :ref="'cknum' + index"
              @keyup.native="moveFocus($event, index, 'cknum')"
              @change.native="up_bannum($event, row.bannumid)"
              :disabled="!row.isno"
              name="cknum"
              v-model="row.cknum"
              placeholder="仓"
            ></el-input>
          </div>
          <!-- 付 Column -->
          <div class="table-cell" style="width: 25px">
            <el-input
              :ref="'paynum' + index"
              @keyup.native="moveFocus($event, index, 'paynum')"
              @change.native="up_bannum($event, row.bannumid)"
              :disabled="!row.isno"
              name="paynum"
              v-model="row.paynum"
              placeholder="付"
            ></el-input>
          </div>
          <!-- 剩余 Column -->
          <div class="table-cell" style="width: 60px">
            <div class="table_name table_isno">
              <div v-if="row.isno" class="table_name_btn">
                <el-button slot="reference">
                  <el-button
                    icon="el-icon-remove"
                    @click="surnum_fn(row)"
                    circle
                    size="mini"
                  ></el-button>
                </el-button>
              </div>
              <el-input
                :ref="'surnum' + index"
                @keyup.native="moveFocus($event, index, 'surnum')"
                @change.native="up_bannum($event, row.bannumid)"
                :disabled="!row.isno"
                name="surnum"
                v-model="row.surnum"
                placeholder="剩余"
              ></el-input>
            </div>
          </div>
          <!-- 上 Column -->
          <div class="table-cell" style="width: 25px">
            <el-input
              :ref="'onnum' + index"
              @keyup.native="moveFocus($event, index, 'onnum')"
              @change.native="up_bannum($event, row.bannumid)"
              :disabled="!row.isno"
              name="onnum"
              v-model="row.onnum"
              placeholder=""
            ></el-input>
          </div>
          <!-- 显 disnum -->
          <div class="table-cell" style="width: 25px">
            <el-input
              :ref="'disnum' + index"
              @keyup.native="moveFocus($event, index, 'disnum')"
              @change.native="up_bannum($event, row.bannumid)"
              :disabled="!row.isno"
              name="disnum"
              v-model="row.disnum"
              placeholder=""
            ></el-input>
          </div>
          <!-- 未 Column -->
          <div class="table-cell" style="width: 20px">
            <el-input
              :ref="'uppaid' + index"
              @keyup.native="moveFocus($event, index, 'uppaid')"
              @change.native="up_bannum($event, row.bannumid)"
              :disabled="!row.isno"
              name="bag"
              v-model="row.uppaid"
              placeholder=""
            ></el-input>
          </div>
          <!-- 红 Column -->
          <div class="table-cell" style="width: 21px">
            <el-switch
              class="bag_switch"
              @change="up_bag($event, row.bannumid)"
              v-model="row.bag"
              active-color="#ff4949"
              inactive-color="#DCDFE6"
            ></el-switch>
          </div>
        </div>
      </div>

      <div v-if="data.bannum.length != 0" class="flex kutong">
        <div v-show="data.sell_num">
          总销量<span>{{ data.sell_num }}</span>
        </div>
        <div v-show="totalCsNum">
          下单<span>{{ totalCsNum }}</span>
        </div>
        <div v-show="totalSurNum">
          剩余<span>{{ totalSurNum }}</span>
        </div>
        <div v-show="totalOnNum">
          已上<span>{{ totalOnNum }}</span>
        </div>
        <div v-show="totalDisNum">
          当前<span>{{ totalDisNum }}</span>
        </div>
      </div>

      <!-- 新增表格数据按钮 -->
      <div v-show="ActionsDis" class="btn_add">
        <el-button @click="copyban" size="small"
          ><i class="icon el-icon-copy-document"></i> 复制</el-button
        >
        <el-button @click="addBannum" size="small"
          ><i class="el-icon-circle-plus-outline"></i> 添加</el-button
        >
        <el-button
          v-if="data.bannum.length != 0"
          size="mini"
          @click="up_num_clear"
          ><i class="el-icon-refresh-right"></i> 重置</el-button
        >
        <el-button
          v-if="data.bannum.length != 0"
          size="mini"
          @click="paixu.isno = true"
          ><i class="el-icon-setting"></i> 设置</el-button
        >
        <el-button
          v-if="data.bannum.length != 0 && beidata.length != 0"
          size="mini"
          @click="jisuanbei"
          ><i class="el-icon-setting"></i> sku规格</el-button
        >
      </div>

      <!-- sku规格 -->
      <div v-if="skuid.isno && beidata.length != 0" class="sku_zhan">
        <div class="sku_item" v-for="item in data.bannum" :key="item.bannumid">
          <div class="bannum_paixu">
            <el-input
              class="bannum_paixu_input bannum_sku_input"
              v-model="item.skuid"
              name="skuid"
              @change.native="up_bannum($event, item.bannumid)"
              :min="0"
              :max="data.bannum.length"
            ></el-input>
            <div class="bannum_paixu_tips">
              <span class="bei_color">{{ item.color }}</span>
              <span class="bei_paynum">{{ item.paynum }}</span>
              <span class="bei_paynum bei_paynum_z" v-show="item.skuid"
                >({{ item.paynum_z }})</span
              >
            </div>
            <el-button
              size="mini"
              name="paynum"
              v-show="item.paynum_z && item.paynum_z != item.paynum"
              plain
              @click="jisuanpaynum($event, item)"
              ><i class="el-icon-success"></i> 提交</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- 弹框开始 -->

      <!-- 设置 弹框 -->
      <el-dialog
        title="设置"
        :visible.sync="paixu.isno"
        v-if="data.bannum.length > 0"
        width="70%"
      >
        <div class="flex">
          <div class="img she_img">
            <el-image
              fit="scale-down"
              style="width: 100%; height: 100%"
              :src="
                form.url ||
                data.url + '.jpg' ||
                'https://pic.rmb.bdstatic.com/bjh/5eba9634898886c5650982b3d0ef22ab.png'
              "
              :preview-src-list="[
                form.url ||
                  data.url ||
                  'https://pic.rmb.bdstatic.com/bjh/5eba9634898886c5650982b3d0ef22ab.png',
              ]"
            ></el-image>
          </div>
          <div class="she_descr">
            <div class="flex">
              <div class="flex b10 r5">
                <el-input v-model="form.url" autocomplete="off"></el-input>
                <el-button type="primary" @click="click_from">确 定</el-button>
                <el-button type="primary" @click="click_from_one"
                  >使用sku图片</el-button
                >
              </div>
              <div class="remainingStock flex">
                <div>本来的销量</div>
                <!-- {{data}} -->
                <el-input
                  @change="change_remainingStock_fn"
                  v-model="data.remainingStock"
                ></el-input>
              </div>
            </div>
            <div>
              <el-descriptions>
                <el-descriptions-item label="ID"
                  ><el-tag type="danger" size="mini">{{
                    data.commodity_id
                  }}</el-tag></el-descriptions-item
                >
                <el-descriptions-item v-show="data.sell_num" label="总销量">{{
                  data.sell_num
                }}</el-descriptions-item>
                <el-descriptions-item
                  v-for="(item, index) in fetchedData.product_format"
                  :key="index"
                  :label="item.PropertyName"
                >
                  {{ item.Name }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <!-- {{form}}
        {{data.url}} -->
          </div>
        </div>
        <hr />
        <div class="flex">
          <div class="r10">规格设置 共{{ data.bannum.length }}个规格</div>
          <el-button type="primary" size="mini" @click="skuidkucunOneClickSet"
            >一键设置</el-button
          >
        </div>
        <div>{{ fetchedData.name }}</div>
        <div class="paixu_con flex">
          <div>
            <div v-for="(item, index) in data.bannum" :key="index">
              <div class="bannum_paixu">
                <div class="bannum_paixu_tips">
                  颜色:{{ item.color }} 剩余数量:{{ item.surnum }}
                </div>
                <el-input
                  class="bannum_paixu_input paixu_input"
                  v-model.number="item.paixu"
                  name="paixu"
                  @change.native="up_bannum($event, item.bannumid)"
                  :min="0"
                  :max="data.bannum.length"
                  type="number"
                ></el-input>
                <el-input
                  class="bannum_paixu_input"
                  v-model.number="item.skuidkucun"
                  name="skuidkucun"
                  @change.native="up_bannum($event, item.bannumid)"
                  :min="0"
                  :max="data.bannum.length"
                ></el-input>
              </div>
            </div>
          </div>
          <div class="l10">
            <div
              class="paixu_hei flex"
              v-for="(item, index) in fetchedData.newArr"
              :key="index"
            >
              <div>
                {{ item.shippingTime }}
              </div>
              <el-tag class="l10" type="danger" size="mini">{{
                item.id
              }}</el-tag>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <div>设置库存id</div>
          <div class="flex">
            <el-input
              v-model="data.inventory_text"
              placeholder="库存文本"
              name="inventory_text"
              @change="change_inventory_text_fn"
            ></el-input>
            <el-button @click="updateInventoryTextAndQuery">设置内容为:{{ inventory_text_old }}</el-button>
            <el-button @click="kdzsidkucunOneClickSet">一键设置</el-button>
            <el-button @click="queryKucunData">查询</el-button>
          </div>
          <div class="flex">
            <div class="r10">
              <div v-for="(item, index) in data.bannum" :key="index">
                <div class="bannum_paixu">
                  <div class="bannum_paixu_tips">
                    颜色:{{ item.color }} 剩余数量:{{ item.surnum }}
                  </div>
                  <!-- 快递组手的 sysItemSkuId -->
                  <el-input
                    class="bannum_paixu_input"
                    name="sysItemSkuId"
                    v-model="item.sysItemSkuId"
                    @change.native="up_bannum($event, item.bannumid)"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="r10 kucun_results kucun_top">
              <div class="kucun_item">
                <div class="kucun_color">
                  <div>颜色</div>
                </div>
                <div class="kucun_img">
                  <div>图片</div>
                </div>
                <div class="kucun_salableItemStock">
                  <div>总数</div>
                </div>
                <div class="kucun_salableItemDistributableStock">
                  <div>可售</div>
                </div>
                <div class="kucun_pan">
                  <div>sysItemSkuId</div>
                </div>
              </div>
              <div class="scroll kucun_results_list">
                <div
                  class="kucun_item"
                  v-for="stockInfo in data.kdzs.kc.list"
                  :key="stockInfo.skuOuterId"
                >
                  <div class="kucun_color">
                    <div>{{ stockInfo.skuOuterId }}</div>
                  </div>
                  <div class="kucun_img">
                    <el-image
                      fit="scale-down"
                      style="width: 100%; height: 100%"
                      :src="stockInfo.picUrl + '.jpg'"
                      :preview-src-list="[stockInfo.picUrl]"
                      lazy
                    ></el-image>
                  </div>
                  <div class="kucun_salableItemStock">
                    <div>{{ stockInfo.salableItemStock }}</div>
                  </div>
                  <div class="kucun_salableItemDistributableStock">
                    <div>{{ stockInfo.salableItemDistributableStock }}</div>
                  </div>
                  <div>
                    {{ stockInfo.sysItemSkuId }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="scroll douyin_data">
                <div class="download-button">
                  <el-button type="warning" @click="downloadMainPic"
                    >下载主图</el-button
                  >
                  <el-button type="success" @click="downloadSrcArray"
                    >下载详细图</el-button
                  >
                  <el-button type="primary" @click="batchDownloadImages"
                    >下载sku图</el-button
                  >
                  <el-button type="primary" @click="batchDownloadAll"
                    >一键下载</el-button
                  >
                </div>
                <div
                  v-for="item in fetchedData.newArr"
                  :key="item.sell_properties.id"
                  class="flex douyin_data_item"
                >
                  <div class="douyin_data_img r10">
                    <el-image
                      v-if="item.img"
                      :src="item.img + '.jpg'"
                      :preview-src-list="[item.img + '.jpg']"
                      alt="商品图片"
                      style="max-width: 80px"
                    >
                    </el-image>
                  </div>
                  <div class="douyin_data_id r10">
                    <el-tag class="l10" type="danger" size="mini">{{
                      item.id
                    }}</el-tag>
                  </div>
                  <div class="douyin_data_title flex">
                    <div>
                      <el-tag class="l10 flex" type="danger" size="mini"
                        ><div class="r5">{{ item.sell_properties[0] }}</div>
                        <div>{{ item.sell_properties[1] }}</div></el-tag
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- <el-table :data="fetchedData.newArr" style="width: 100%">
            <el-table-column
              width="200px"
              prop="id"
              label="SKU_ID"
            ></el-table-column>
            <el-table-column width="220px" label="款号">
              <template slot-scope="scope">
                <div>{{ scope.row.sell_properties[0] }}</div>
                <div>{{ scope.row.sell_properties[1] }}</div>
              </template>
            </el-table-column>
            <el-table-column label="图片" width="80px">
              <template slot-scope="scope">
                <el-image
                  v-if="scope.row.img"
                  :src="'https://p3-aio.ecombdimg.com/obj/' + scope.row.img"
                  :preview-src-list="[
                    'https://p3-aio.ecombdimg.com/obj/' + scope.row.img,
                  ]"
                  alt="商品图片"
                  style="max-width: 80px"
                >
                </el-image>
              </template>
            </el-table-column>
          </el-table> -->
            </div>
          </div>
        </div>
        <hr />
        <div class="fetchedImg">
          <h2 class="title">主图</h2>
          <div class="flex">
            <div v-for="(mainPic, index) in fetchedData.pic" :key="index">
              <el-image
                lazy
                :src="mainPic"
                fit="cover"
                style="width: 150px; height: 150px"
                :preview-src-list="[mainPic]"
              >
              </el-image>
            </div>
          </div>

          <h2 class="title">详细图</h2>
          <div class="flex">
            <div
              v-for="(detailPic, index) in fetchedData.srcArray"
              :key="index + 'detail'"
            >
              <el-image
                lazy
                :src="detailPic"
                fit="cover"
                style="width: 150px"
                :preview-src-list="[detailPic]"
              >
              </el-image>
            </div>
          </div>

          <h2 class="title">sku图</h2>
          <div class="flex sku_div">
            <div
              class="item"
              v-for="(sku, index) in fetchedData.newArr"
              :key="index + 'sku'"
            >
              <div class="title cur">
                {{ sku.sell_properties[0] }}
              </div>
              <el-image
                lazy
                :src="sku.img"
                fit="cover"
                style="width: 150px; height: 150px"
                :preview-src-list="[sku.img]"
              >
              </el-image>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 弹框结束 -->
    </div>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  name: "Card",
  components: { ElImageViewer },
  data() {
    return {
      data1: {},
      batchPrice: "", //批量价格
      batchStock: "", //批量库存
      showSkuDetails: false, //是否显示抖音数据
      intervalId: null,
      isFetching: false,
      showViewer: false, //图片放大
      dialogFormVisible: false, //修改图片弹框 是否显示
      UpdateIdVisible: false, //修改ID弹框 是否显示
      paixu: {
        //设置规格排序 弹框
        isno: false,
      },
      fetchedData: {
        newArr: [],
        name: "",
      }, // 用于保存从接口获取的数据
      // 设置规格id
      skuid: {
        isno: false,
      },
      form: {
        //修改图片弹框 form
        url: "",
      },
      n: 0, //仓库 - 支付 = 计算
      formLabelWidth: "120px", //修改图片弹框 宽度
      declItems: 0,
      kdzs: {
        kc: {
          list: [],
        },
      },
      inventory_text_old: "222",
    };
  },
  props: {
    // 主要数据
    data: {
      type: Object,
      default: () => {
        return {
          url: "https://pic.rmb.bdstatic.com/bjh/5eba9634898886c5650982b3d0ef22ab.png",
          text: "内容",
        };
      },
    },
    BinImg: {
      type: Boolean,
      default: false,
    },
    BinImg_num: {
      type: Number,
      default: 80,
    },
    ActionsDis: {
      type: Boolean,
      default: true,
    },
    paixudata: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 备货单数据
    beidata: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.jisuanbei();
  },
  methods: {
    // 复制文本内容
    copy_sku_list() {
      console.log(this.data);
    },
    // 批量下载
    batchDownloadAll() {
      const allImages = [];

      // Add main pic images to the array
      this.fetchedData.pic.forEach((pic, index) => {
        if (pic) {
          allImages.push({ url: pic, fileName: `0主图-${index}` });
        }
      });

      // Add src array images to the array
      this.fetchedData.srcArray.forEach((src, index) => {
        if (src) {
          allImages.push({ url: src, fileName: `1详细图-${index}` });
        }
      });

      // Add batch download images to the array
      this.fetchedData.newArr.forEach((item) => {
        if (item.img) {
          allImages.push({
            url: "https://p3-aio.ecombdimg.com/obj/" + item.img,
            fileName: "2" + item.sell_properties[0],
          });
        }
      });

      // Loop through all images and download them
      allImages.forEach((image) => {
        this.downloadImage(image.url, image.fileName);
      });
    },
    downloadMainPic() {
      // console.log(this.fetchedData);
      // return;
      this.fetchedData.pic.forEach((pic, index) => {
        if (pic) {
          this.downloadImage(pic + ".jpg", `主图-${index + 1}`);
        }
      });
    },
    downloadSrcArray() {
      this.fetchedData.srcArray.forEach((src, index) => {
        if (src) {
          this.downloadImage(src + ".jpg", `详细图-${index + 1}`);
        }
      });
    },
    batchDownloadImages() {
      this.fetchedData.newArr.forEach((item, index) => {
        if (item.img) {
          this.downloadImage(
            item.img + ".jpg",
            `${index}${item.sell_properties[0]}`
          );
        }
      });
    },

    downloadImage(imgUrl, fileName) {
      fetch(imgUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}.jpg`); // Use fileName for download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading image:", error);
        });
    },
    // 通用的方法用于一键设置
    async oneClickSet(dataArray, apiUrl, successMessage, skuname) {
      try {
        if (this.data.bannum.length === dataArray.length) {
          const newArray = this.data.bannum.map((item, index) => {
            return {
              [skuname]: dataArray[index].id || dataArray[index].sysItemSkuId, // 适应不同数据结构
              bannumid: item.bannumid,
            };
          });

          const res = await this.$axios.post(apiUrl, newArray);

          if (res.data.code === 200) {
            this.$message.success(successMessage);
            this.$socket.emit("up_guige_table_data", this.data.id);
          } else {
            this.$message.error("错误");
          }
        } else {
          this.$message.error("长度不一致!");
        }
      } catch (error) {
        console.error("请求出错", error);
        this.$message.error("请求出错");
      }
    },
    /** skuidkucunOneClickSet 一键设置抖音sku */
    skuidkucunOneClickSet() {
      this.oneClickSet(
        this.fetchedData.newArr,
        "/api/kanban/skuidkucunOneClickSet",
        "一键设置成功",
        "skuidkucun"
      );
    },
    /** kdzsidkucunOneClickSet 快递组手id */
    kdzsidkucunOneClickSet() {
      const sortedKcList = this.data.bannum.map(item => this.data.kdzs.kc.list.find(kcItem => kcItem.skuOuterIdSpace1 === item.color));

      this.oneClickSet(
        sortedKcList,
        "/api/kanban/kdzsidkucunOneClickSet",
        "一键设置成功",
        "sysItemSkuId"
      );
    },
     // 新方法：更新 inventory_text 并执行查询
    updateInventoryTextAndQuery() {
      this.data.inventory_text = this.inventory_text_old; // 修改 inventory_text
      this.change_inventory_text_fn();
      this.queryKucunData(); // 执行查询
    },
    // 库存查询
    queryKucunData() {
      let { inventory_text } = this.data;
      // 使用逗号分隔符拆分字符串，并去掉空格
      let inventoryArray = inventory_text
        .split("、")
        .map((item) => item.trim());

      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/kucunPageListAll", // 这里应该填入你的库存查询API
        data: {
          inventoryArray,
        },
      })
        .then((res) => {
          this.data.kdzs.kc.list = res.data.list.map(item => {
            return {
              ...item,
              skuOuterIdSpace1: item.skuOuterId.split(' ')[1]
            };
          });
          console.log(this.data.kdzs.kc.list)
        })
        .catch((error) => {
          console.error(error);
        });

      if (!inventory_text) {
        this.$message.error("规格编码不能为空");
        return;
      }
      return;
    },
    // 批量输入价格
    batchUpdate() {
      if (this.batchPrice) {
        if (!isNaN(parseFloat(this.batchPrice)) && isFinite(this.batchPrice)) {
          this.fetchedData.newArr.forEach((row) => {
            row.inputPrice = this.batchPrice;
          });
        } else {
          alert("请输入有效的价格");
          return;
        }
      }
      if (this.batchStock) {
        if (
          !isNaN(parseFloat(this.batchStock)) &&
          isFinite(this.batchStock) &&
          Number.isInteger(parseFloat(this.batchStock))
        ) {
          this.fetchedData.newArr.forEach((row) => {
            row.inputStock = this.batchStock;
          });
        } else {
          alert("请输入有效的库存数");
          return;
        }
      }
    },
    // 金额计算
    formatPrice(price) {
      return (price / 100).toFixed(2);
    },
    onPriceChange(row) {
      let price = parseFloat(row.inputPrice) * 100;
      if (isNaN(price)) {
        this.$message.error("请输入有效的数字");
      } else {
        row.newPrice = price;
      }
    },
    onPriceConfirm(row) {
      let price = parseFloat(row.inputPrice) * 100;
      if (isNaN(price)) {
        this.$message.error("请输入有效的数字");
      } else {
        // 使用封装的axios发送请求
        this.$axios({
          method: "post",
          url: "/api/kanban/updateSkuPrice",
          data: {
            sku_id: row.id,
            price: price,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            row.price = price;
            this.$message.success("价格已更新");
          } else {
            this.$message.error("更新价格失败");
          }
        });
      }
    },
    // 库存修改
    onStockChange(row) {
      let stock = parseInt(row.inputStock);
      if (isNaN(stock)) {
        this.$message.error("请输入有效的库存数字");
      } else {
        row.inputStock = stock;
      }
    },
    onStockConfirm(row) {
      let stock = parseInt(row.inputStock);
      if (isNaN(stock)) {
        this.$message.error("请输入有效的库存数字");
      } else {
        this.$axios({
          method: "post",
          url: "/api/kanban/updateSkuStocks",
          data: {
            product_id: row.product_id, // 你需要确保 row 对象有 id 属性
            sku_sync_list: [
              {
                sku_id: row.id, // 你需要确保 row 对象有 sku_id 属性
                stock_num: row.inputStock,
              },
            ],
          },
        })
          .then((res) => {
            if (res.data.code == 200) {
              row.stock_num = stock;
              this.$message({
                message: "库存更新成功",
                type: "success",
                duration: 1000,
              });
            } else {
              this.$message({
                message: "库存更新失败",
                type: "error",
                duration: 1000,
              });
            }
          })
          .catch((error) => {
            console.error(error);
            this.$message({
              message: "库存更新失败",
              type: "error",
              duration: 1000,
            });
          });
      }
    },
    // 同时改库存 updateSkuStocksAndPrices onAllConfirm
    updateSkuStocksAndPrices() {
      let sku_sync_list = this.fetchedData.newArr
        .filter((row) => row.stock_num !== row.inputStock)
        .map((row) => ({
          sku_id: row.id,
          stock_num: row.inputStock,
        }));

      let sku_price_list = this.fetchedData.newArr
        .filter((row) => row.price !== row.inputPrice * 100) // 检查价格是否有更改，并进行单位转换
        .map((row) => ({
          sku_id: row.id,
          price: row.inputPrice * 100, // 将价格乘以100以匹配你的格式
        }));

      let data = {
        product_id: this.data.commodity_id,
        sku_sync_list: sku_sync_list,
        sku_price_list: sku_price_list,
      };

      // 判断sku_sync_list 和 sku_price_list 是否都为空
      if (sku_sync_list.length === 0 && sku_price_list.length === 0) {
        this.$message({
          message: "没有需要更新的库存或价格",
          type: "warning",
          duration: 1000,
        });
        return;
      }

      this.$axios({
        method: "post",
        url: "/api/kanban/updateSkuStocksAndPrices",
        data: data,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "库存和价格全部更新成功",
            type: "success",
            duration: 1000,
          });
          this.fetchedData.newArr.forEach((row) => {
            if (row.stock_num !== row.inputStock) {
              row.stock_num = row.inputStock;
            }
            if (row.price !== row.inputPrice * 100) {
              // 如果价格已修改
              row.price = row.inputPrice * 100; // 更新表格中的价格数据
            }
          });
        }
      });
    },

    // 同步库存按钮
    synchronizeInventory() {
      if (this.data.inventory_text) {
        let bannum_s = this.data.bannum.map((item) => ({
          sysItemSkuId: item.sysItemSkuId,
          bannumid: item.bannumid,
        }));
        this.$socket.emit("get_kdzs_kc_one", {
          bannum: bannum_s,
          id: this.data.id,
          inventory_text: this.data.inventory_text,
        });
      } else {
        this.$message.error("发送错误,检查是否设置库存id");
      }
    },
    // 获取抖音数据
    get_douyin_one() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/douyinku",
        data: {
          id: this.data.commodity_id,
        },
      }).then((res) => {});
      // return;
      if (
        this.data.bannum.some(
          (item) => item.skuidkucun && item.skuidkucun.trim() !== ""
        )
      ) {
        this.$socket.emit("get_douyin_one", {
          data: this.data,
          id: this.data.id,
        });
      } else {
        this.$message.error("发送错误,检查是否设置skuid");
      }
    },
    //重复获取
    toggleDouyinDataFetch() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.isFetching = false;
      } else {
        this.intervalId = setInterval(this.get_douyin_one, 1000); // Change 1000 to the desired interval in milliseconds
        this.isFetching = true;
      }
    },
    // 获取单个数据
    get_data_one() {
      this.$socket.emit("get_data_one", this.data.id);
    },
    // 计算备货单
    jisuanbei() {
      if (!this.data.kdzs) {
        this.data.kdzs = {
          kc: {
            list: [],
          },
        };
      }
      this.skuid.isno = !this.skuid.isno;
      this.data.bannum.forEach((item) => {
        this.beidata.forEach((item1) => {
          if (item1.skuId == item.skuid) {
            item.paynum_z = item1.count;
          }
        });
      });
    },
    // 提交备货单计算数据
    jisuanpaynum(event, data) {
      data.paynum = data.paynum_z + "";
      event.target.name = "paynum";
      event.target.value = data.paynum;
      this.up_bannum(event, data.bannumid);
      this.surnum_fn(data);
    },
    // 移开焦点排序
    change_xnum() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_data_xnum",
        data: {
          id: this.data.id,
          xnum: this.data.xnum,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          // this.$socket.emit("data");
          this.$socket.emit("up_paixu", res.data.xu);
        }
      });
    },
    // 设置多少号链接
    change_link_num() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_data_link_num",
        data: {
          id: this.data.id,
          link_num: this.data.link_num,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_isx");
        }
      });
    },
    // 显示修改图片 弹框
    show_from() {
      this.dialogFormVisible = true;
      this.form.url = this.data.url;
    },
    // 使用sku图片
    click_from_one() {
      if (!this.fetchedData.newArr[0].img && !this.fetchedData) {
        this.$message.error("获取图片失败");
        return;
      }
      let oImg = `${this.fetchedData.pic[0]}`;
      this.form.url = oImg;
      this.click_from();
    },
    // 修改图片 方法
    click_from() {
      this.data.url = this.form.url;

      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_data_url",
        data: {
          id: this.data.id,
          url: this.data.url,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.form.url = "";
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_img_data", this.data.id);
        }
      });
    },
    // 跳转修改
    go_up() {
      this.data.commodity_id &&
        window.open(
          `https://fxg.jinritemai.com/ffa/g/create?product_id=${this.data.commodity_id}`
        );
    },
    go_doudian() {
      this.data.commodity_id &&
        window.open(
          `https://yckanban.jackafan.top/api/kanban/douyindata1?id=${this.data.commodity_id}`
        );
    },
    // 双击 图片显示到看板
    dbclick_img() {
      clearTimeout(this.time);
      this.up_isx();
    },
    // 单击 打开图片方法
    onPreview() {
      this.showViewer = true;
    },
    //图片单击放大
    click_img() {
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        this.showViewer = true;
      }, 200);
    },
    // 关闭图片放大
    closeViewer() {
      this.showViewer = false;
    },
    // 修改id
    triggerUpdateId() {
      this.$emit("triggerUpdateId", this.data); // 发射事件并传递必要的数据
    },
    // 复制id
    onCopySuccess() {
      this.$message.success("复制成功");
    },
    onCopyError() {
      this.$message.error("复制失败");
    },
    // 修改基本信息
    change_basic_fn() {
      this.data.text_loading1 = true;
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_basic_data",
        data: {
          id: this.data.id,
          basic: this.data.basic,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.data.text_loading1 = false;
          this.$forceUpdate();
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          if (this.data.isx == 1) {
            this.$socket.emit("ban"); //发送看板提示更新
          }
          this.$socket.emit("up_data_basic", this.data.id);
        }
      });
    },
    // 修改库存文本
    change_inventory_text_fn() {
      this.data.text_loading1 = true;
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_inventory_text",
        data: {
          id: this.data.id,
          inventory_text: this.data.inventory_text,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.data.text_loading1 = false;
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          if (this.data.isx == 1) {
            this.$socket.emit("ban"); //发送看板提示更新
          }
        }
      });
    },
    // 修改本来的库存
    change_remainingStock_fn() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/updateRemainingStock",
        data: {
          id: this.data.id,
          remainingStock: this.data.remainingStock,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          if (this.data.isx == 1) {
            this.$socket.emit("ban"); //发送看板提示更新
          }
        }
      });
    },
    // 修改文本
    change_fn() {
      this.data.text_loading = true;
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_data",
        data: {
          id: this.data.id,
          text: this.data.text,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.data.text_loading = false;
          this.$forceUpdate();
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          if (this.data.isx == 1) {
            this.$socket.emit("ban"); //发送看板提示更新
          }
          this.$socket.emit("up_data_text", this.data.id);
        }
      });
    },
    // 表格键盘移动
    moveFocus(event, index, key) {
      // 向上 = 38
      if (event.keyCode === 38) {
        if (index === 0) {
          // 第一行
          return;
        }
        this.$refs[key + index][0].blur();
        this.$nextTick(() => {
          this.$refs[key + (index - 1)][0].focus();
          setTimeout(() => {
            this.$refs[key + (index - 1)][0].select();
          }, 0);
        });
      }
      // 下 = 40
      if (event.keyCode === 40) {
        if (this.declItems == 0) {
          this.declItems = this.data.bannum.filter((item) => {
            return item.isno == 1;
          }).length;
        }
        if (index === this.declItems - 1) {
          // 最后一行
          return;
        }
        this.$refs[key + index][0].blur();
        this.$nextTick(() => {
          this.$refs[key + (index + 1)][0].focus();
          setTimeout(() => {
            this.$refs[key + (index + 1)][0].select();
          }, 0);
        });
      }
    },
    // 修改是否到看板
    up_isx() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_data_isx",
        data: {
          id: this.data.id,
          isx: this.data.isx,
        },
      }).then((res) => {
        if (res.data.code == 1 || res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_isx");
        }
      });
    },
    // 修改是否到固定
    up_isg() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_data_isg",
        data: {
          id: this.data.id,
          isg: this.data.isg,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          // this.$socket.emit("up_data",this.data.id);
          this.$socket.emit("up_data_isg");
        }
      });
    },
    // 修改是否到提示
    up_isbadge() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_data_isbadge",
        data: {
          id: this.data.id,
          isbadge: this.data.isbadge,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          // this.$socket.emit("up_data",this.data.id);
          this.$socket.emit("up_data_isbadge");
        }
      });
    },
    // 修改已上/显示数量
    up_num_clear() {
      let isClear = this.data.bannum.every((item) => {
        if (item.disnum == null) {
          item.disnum = "";
        }
        if (item.onnum == null) {
          item.onnum = "";
        }
        return item.disnum == "" && item.onnum == "";
      });
      if (isClear) {
        this.$message({
          message: "无须重置",
          duration: 1000,
        });
        return;
      }
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_num_clear",
        data: {
          id: this.data.id,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_num_clear", this.data.id);
        }
      });
    },
    // 表格数据修改
    up_bannum(event, bannumid) {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_bannum",
        data: {
          type: event.target.name,
          value: event.target.value,
          bannumid,
          fid: this.data.id,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_table_data", {
            type: event.target.name,
            value: event.target.value,
            bannumid,
          });
        }
      });
    },
    // 修改背景颜色红
    up_bag(val, bannumid) {
      let o = {
        target: {
          name: "bag",
          value: val,
        },
      };
      this.up_bannum(o, bannumid);
    },
    //隐藏表格数据
    del_bannum(bannumid) {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/del_bannum",
        data: {
          bannumid,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_guige_table_data", this.data.id);
        }
      });
    },
    // 显示表格数据
    isXian(bannumid) {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_bannum",
        data: {
          type: "isno",
          value: "1",
          bannumid,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_guige_table_data", this.data.id);
        }
      });
    },
    // 计算
    surnum_fn(data) {
      this.n = 0;
      if (!data.cknum) {
        this.n = data.csnum - data.paynum;
      } else {
        this.n = data.cknum - data.paynum;
      }
      this.n = this.n < 0 ? "超" + Math.abs(Number(this.n)) : this.n;
      if (this.n == data.surnum) {
        this.$message({
          message: "无须计算~",
          duration: 1000,
        });
        return;
      }
      data.surnum = this.n;

      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_bannum",
        data: {
          type: "surnum",
          value: this.n,
          bannumid: data.bannumid,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_table_data", {
            type: "surnum",
            value: this.n,
            bannumid: data.bannumid,
          });
        }
      });
    },
    // 快速添加
    b_up_text() {
      this.data.text += `
<b>Strong</b>`;
      this.change_fn();
    },
    red_up_text() {
      if (this.data.text == "") {
        this.data.text += `()
<i> </i>`;
      } else {
        this.data.text += `
<i> </i>`;
      }
      this.change_fn();
    },
    y_up_text() {
      this.data.text += `
<b> </b>`;
      this.change_fn();
    },
    // 复制卡片
    copyFun() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/copyban",
        data: {
          id: this.data.id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "复制成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_guige_table_data", this.data.id);
          this.$emit("update-data");
        }
      });
    },
    copyban() {
      this.$confirm("是否需要复制卡片？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.copyFun();
        })
        .catch(() => {
          return;
        });
    },
    // 新增表格数据
    addBannum() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/add_bannum",
        data: {
          id: this.data.id,
        },
      }).then((res) => {
        if (res.data == 1) {
          this.$message({
            message: "添加成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_guige_table_data", this.data.id);
        }
      });
    },
    // 设置开始倒计时
    up_counttime() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/counttime",
        data: {
          id: this.data.id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("counttime", {
            id: this.data.id,
            counttime: this.data.counttime,
            isx: this.data.isx,
          });
        }
      });
    },
  },
  computed: {
    buttonType() {
      if (this.data.bannum.length === 0) {
        return "warning"; // 如果data.bannum是空数组
      } else {
        for (const item of this.data.bannum) {
          if (item.sysItemSkuId === null || item.sysItemSkuId === "") {
            return "warning"; // 如果有任何一个sysItemSkuId是null或""
          }
        }
        return ""; // 所有sysItemSkuId都有值
      }
    },
    // 统计
    totalCsNum() {
      return this.data.bannum.reduce(
        (sum, item) => sum + Number(item.csnum),
        0
      );
    },
    totalSurNum() {
      return this.data.bannum.reduce(
        (sum, item) => sum + Number(item.surnum),
        0
      );
    },
    totalOnNum() {
      // 假设onnum空字符串表示0
      return this.data.bannum.reduce(
        (sum, item) => sum + Number(item.onnum || 0),
        0
      );
    },
    totalDisNum() {
      // 假设disnum空字符串表示0
      return this.data.bannum.reduce(
        (sum, item) => sum + Number(item.disnum || 0),
        0
      );
    },
  },
  created() {
    if (!this.data.kdzs) {
      this.data.kdzs = {
        kc: {
          list: [],
        },
      };
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    // this.stopCountdown();
  },
  watch: {
    // 当 paixu.isno 的值改变时，此函数会被调用
    "paixu.isno": {
      handler(newValue) {
        if (newValue) {
          // 当对话框打开的时候，发送请求
          this.$axios({
            method: "post",
            url: "/api/kanban/douyindata",
            data: {
              id: this.data.commodity_id,
            },
          })
            .then((res) => {
              if (res.data.code == 200) {
                const {
                  sell_num,
                  name,
                  srcArray,
                  pic,
                  product_format,
                  newArr,
                } = res.data;

                this.data.sell_num = sell_num;
                this.fetchedData.name = name;

                this.fetchedData.srcArray = srcArray;
                this.fetchedData.pic = pic;
                this.fetchedData.product_format = product_format;
                this.fetchedData.newArr = newArr.map((item) => {
                  return {
                    ...item,
                    newPrice: item.price,
                    inputPrice: this.formatPrice(item.price),
                    inputStock: item.stock_num,
                  };
                });
                this.inventory_text_old = this.fetchedData.newArr[0].sell_properties[0].split(" ")[0];
              } else {
                this.$message.error(`${res.data.data.msg}`);
              }
            })
            .catch((error) => {
              this.$message.error("抖音cookie,过期");
              console.error(error);
            });
        }
      },
      immediate: false, // 立即触发的标志，此处我们只在对话框打开时触发，所以设置为false
    },
    showSkuDetails2: {
      handler(newValue) {
        if (newValue) {
          // 当对话框打开的时候，发送请求
          this.$axios({
            method: "post",
            url: "/api/kanban/douyindata1",
            data: {
              id: this.data.commodity_id,
            },
          })
            .then((res) => {
              this.data.data1 = res.data.data1.description.replace(
                /style="max-width:100%;"\//g,
                " /"
              );
            })
            .catch((error) => {
              console.error(error);
            });
        }
      },
      immediate: false, // 立即触发的标志，此处我们只在对话框打开时触发，所以设置为false
    },
    showSkuDetails: {
      handler(newValue) {
        if (newValue) {
          // 当对话框打开的时候，发送请求
          this.$axios({
            method: "post",
            url: "/api/kanban/douyindata",
            data: {
              id: this.data.commodity_id,
            },
          })
            .then((res) => {
              this.fetchedData = res.data;
              this.fetchedData.newArr = this.fetchedData.newArr.map((item) => {
                return {
                  ...item,
                  newPrice: item.price,
                  inputPrice: this.formatPrice(item.price),
                  inputStock: item.stock_num,
                };
              });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      },
      immediate: false, // 立即触发的标志，此处我们只在对话框打开时触发，所以设置为false
    },
  },
};
</script>

<style scoped>
/* 库存高度 */
.el-dialog__body .kucun_results {
  height: calc(100vh - 850px);
}

.card {
  max-width: 100%;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 20px;
  padding: 6px;
  width: 350px;
  box-shadow: rgb(3 14 44 / 9%) 0px 2px 6px 0px;
  position: relative;
  margin: 0 10px 20px 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-self: flex-start;
  opacity: 0.95;
}
/* 左侧小按钮 */
.card_xnum {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
  height: 24px;
  width: 40px;
}
:deep(.card_xnum input),
:deep(.table input) {
  border: 1px solid #d5d6de;
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
}
:deep(.card_xnum input),
.table input:hover {
  background-color: #fff;
  border-color: #6557fa;
  border-right-width: 1px !important;
}
.card_xnum_url {
  top: 38px;
}
.card_xnum_img {
  top: 66px;
  text-align: center;
  color: #a9a9a9;
}
.card_xnum_img i {
  cursor: url(/public/img/cursor/hand.png) 0 0, auto;
}
.card_img {
  border-radius: 4px;
  cursor: url(/public/img/cursor/hand.png) 0 0, auto;
  display: block;
  min-height: 80px;
}
.card_img img {
  min-width: 100px;
  min-height: 100px;
  border: 1px solid red;
}
.binimg {
  height: 300px;
}
:deep(.card_img img) {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 4px;
}
.img {
  height: 80px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.img img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.commodity_id {
  display: flex;
  justify-content: center;
  width: 100%;
  color: #131520;
  font-size: 12px;
  align-items: flex-start;
}
.id-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 260px;
}
.commodity_id_move_down {
  transform: translateY(2px);
}


.commodity_id .icon {
  font-size: 16px;
  margin: 0 5px;
  cursor: url(/public/img/cursor/hand.png) 0 0, auto;
  color: #a9a9a9;
}

/* 右侧小按钮 */
.card_isx {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  height: 24px;
  width: 40px;
}
/* 固定小按钮 */
.isg {
  top: 34px;
}
/* 提示小按钮 */
.isbadge {
  top: 60px;
}
.counttime {
  top: 64px;
}

/* 输入框 */
.text {
  width: 100%;
}
:deep(.text textarea),
.text {
  box-sizing: border-box;
  min-height: 40px;
  resize: none;
  min-height: 54px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(3, 14, 44, 0.85);
  border-radius: 4px;
  padding: 8px;
  transition: border 0.3s ease 0s;
  white-space: pre-wrap;
  line-height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  border-color: #a9a9a9;
  font-family: "Roboto", sans-serif, ui-sans-serif, system-ui, -apple-system,
    Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue,
    Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji,
    "PingFangSC-Regular", STHeiti, "Hiragino Sans GB", "Microsoft YaHei",
    "Helvetica Neue", Helvetica, Arial, sans-serif, "微软雅黑";
  transition: all 0.5s ease;
  font-weight: bold;
}
.el-textarea {
  border-radius: 4px;
  box-sizing: border-box;
  caret-color: #f6af05;
}
:deep(.text textarea:focus) {
  border-color: #409eff;
}
.text_i:focus {
  border-color: #000;
}
.text_i textarea {
  line-height: 20px;
}

/* 文本快捷输入 */
.fast_text_btn {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.fast_text_div_g1 {
  display: flex;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #ccc;
  line-height: 32px;
  align-items: center;
  justify-content: space-evenly;
  width: 90px;
  width: 65px;
  margin-left: 10px;
  color: #858585;
}
.fast_text_btn_red {
  width: var(--w15);
  height: var(--w15);
  background: #f59595;
  border-radius: 50%;
}
.fast_text_btn_bag {
  width: var(--w15);
  height: var(--w15);
  background: #f8bf6a;
  border-radius: 50%;
}

/* 表格数据 */
:deep(.table_n input) {
  font-size: 13px;
  border: none;
  padding: 0;
  text-align: center;
}
:deep(.table_n .table_name input) {
  padding: 4px 2px 4px 20px;
  text-align: left;
  font-size: 14px;
}
:deep(.table_n .table_isno input) {
  padding-left: 18px;
  text-align: center;
  font-size: 13px;
}
:deep(.table_n .el-table__row .cell) {
  padding: 0 !important;
}
:deep(.table_n .has-gutter tr th div) {
  padding: 0;
  text-align: center;
  font-weight: normal;
  color: #909399;
}
.btn_add {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
}
:deep(.el-table .cell) {
  text-overflow: clip;
}
.table_name {
  position: relative;
}
.table_name_btn {
  position: absolute;
  top: 0;
  left: 2px;
  width: 16px;
  height: 16px;
  z-index: 2;
}
:deep(.table tr td),
:deep(.table tr th) {
  padding: 0;
}
:deep(.table_name button) {
  width: 16px;
  height: 16px;
  z-index: 2;
  padding: 0;
}
.table_name_btn_huifu {
  position: absolute;
  top: 4px;
  left: 10px;
}
.bag_switch {
  width: 20px;
  transform: translateY(-1px);
}

/* 关键词 */
.keyword {
  display: flex;
}
.keyword span {
  background: #7c8597;
  color: #fff;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 10px;
  display: inline-block;
  min-height: 20px;
  margin: 0 6px;
  cursor: url(/public/img/cursor/hand.png) 0 0, auto;
  transition: 0.3s;
}
.keyword span:hover {
  background: #5b677d;
}
:deep(.keyword_btn) {
  padding: 0 4px;
}

/* 规格排序 */
.bannum_paixu {
  display: flex;
  height: 40px;
  line-height: 40px;
  align-items: center;
}
.bannum_paixu_tips {
  margin-right: 10px;
}
:deep(.bannum_paixu .el-input) {
  width: auto;
}
:deep(.bannum_paixu input) {
  width: 190px;
  height: 30px;
  padding-right: 0;
}
:deep(.paixu_input input) {
  width: 60px;
  height: 30px;
  padding-right: 0;
}
:deep(.bannum_sku_input input) {
  width: 180px;
  margin-right: 10px;
}
.sku_zhan {
  padding-top: 20px;
}
.sku_item {
  height: 40px;
  line-height: 40px;
}
.sku_item .el-button--mini {
  height: 30px;
}
.bei_color {
  margin-right: 10px;
}
.bei_paynum {
  color: #606266;
}
.bei_paynum_z {
  font-size: 12px;
}
.paixu_hei {
  line-height: 30px;
}

/* 同时改 */
/* :deep(.showSkuDetails tr div){background:pink;padding:0;} */
/* :deep(.showSkuDetails .el-table .el-table__cell div){padding:0;}
:deep(.showSkuDetails .el-table th.el-table__cell>.cell){padding:0;} */
.showSkuDetails {
  max-height: 340px;
  font-size: 12px;
}
.showSkuDetails_list {
  max-height: 150px;
}
.showSkuDetails_title {
  width: 110px;
}
.xinxi_1 {
  width: 60px;
}
.xinxi_2 {
  width: 45px;
}
.xinxi_3 {
  width: 135px;
}
/* :deep(.showSkuDetails input){height:24px; line-height:24px;font-size:12px;} */

.table-row {
  display: flex;
  flex-wrap: wrap;
}

.table-cell {
  /* padding: 10px; */
  display: flex;
  align-items: center;
  border: 1px solid #ebeef5;
  box-sizing: border-box;
  flex-grow: 1;
}
:deep(.table-cell .el-input input) {
  padding: 0;
  border: none;
}
.header-row {
  color: #909399;
  font-size: 14px;
}
:deep(.table-cell .el-input input) {
  text-align: center;
}
.header-row .table-cell {
  justify-content: center;
}
:deep(.table_isno .el-input input) {
  padding-left: 10px;
  font-size: 13px;
}
:deep(.color .el-input input) {
  padding-left: 20px;
  text-align: left;
}

/* 库存 */
.kucun_results {
  font-size: 12px;
  padding-top: 10px;
}
.kucun_results .kucun_results_list {
  padding-right: 10px;
  min-height: 130px;
  max-height: 260px;
}
.kucun_results .kucun_item {
  display: flex;
  min-height: 23px;
  max-height: 20px;
}
.kucun_results_header {
  text-align: center;
}
.kucun_top .kucun_item {
  min-height: 30px;
  text-align: center;
}
.kucun_color {
  width: 100px;
}
.kucun_img {
  width: 50px;
}
.kucun_salableItemStock {
  width: 30px;
  text-align: center;
}
.kucun_salableItemDistributableStock {
  width: 30px;
  text-align: center;
}
.kucun_pan {
  flex: 1;
}
.kucun_results {
  padding-bottom: 100px;
  height: calc(100vh - 420px);
}

/* 抖音库存 */
.douyin_data {
  padding-top: 0;
  line-height: 30px;
  min-height: 140px;
}
.douyin_data_item {
  height: 30px;
}
.douyin_data_title {
  width: 220px;
}
.douyin_data .douyin_data_img {
  width: 30px;
}

/* 设置 */
.she_img {
  width: 150px;
  margin-right: 10px;
  overflow: hidden;
  height: 150px;
  border: 2px dashed #515151;
  border-radius: 10px;
}
.she_descr {
  flex: 1;
}

.kutong {
  width: 100%;
  display: flex;
  justify-content: space-around; /* 在项目之间提供了相等的间隔 */
  align-items: center; /* 垂直居中 */
  background-color: #f7f7f7; /* 设置背景颜色 */
  padding: 20px; /* 设置内边距 */
  padding: 4px 20px;
  border-radius: 10px; /* 设置边框圆角 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 设置盒子阴影 */
}

.kutong > div {
  text-align: center; /* 文本居中 */
}

.kutong > div > span {
  display: block; /* 使span元素表现得像块元素 */
  padding: 10px; /* 设置内边距 */
  margin-top: 5px; /* 设置上外边距 */
  background-color: #fff; /* 设置背景颜色 */
  border-radius: 5px; /* 设置边框圆角 */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* 设置盒子阴影 */
  font-weight: bold; /* 设置字体加粗 */
  color: #333; /* 设置字体颜色 */
}

.remainingStock {
  width: 200px;
}
.remainingStock div {
  width: 80px;
  line-height: 40px;
}
/* 设置End */

/**
  主图
 */
:deep(.fetchedImg .el-image) {
  border: 2px dashed #909399;
  border-radius: 10px;
  margin-right: 4px;
}
.fetchedImg .flex {
  flex-direction: row;
  flex-wrap: wrap;
}

/** Sku图 */
.sku_div {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* 控制子元素之间的间隔 */
  padding: 20px; /* 外边距 */
}

.sku_div .item {
  width: calc(25% - 20px); /* 每个元素占据的宽度，减去间隔 */
  text-align: center; /* 文本居中 */
  background-color: #f9f9f9; /* 背景颜色 */
  border-radius: 10px; /* 圆角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  overflow: hidden; /* 防止子元素溢出 */
  transition: transform 0.3s, box-shadow 0.3s; /* 过渡效果 */
}

.sku_div .item:hover {
  transform: translateY(-5px); /* 鼠标悬停时上移 */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 增强阴影效果 */
}

.sku_div .item img {
  width: 100%;
  height: auto; /* 保持图片比例 */
  display: block; /* 移除默认的间距 */
  border-bottom: 2px solid #ddd; /* 底部边框 */
}

.sku_div .title {
  color: #333; /* 颜色调整 */
  background-color: rgba(255, 255, 255, 0.85); /* 半透明背景 */
  padding: 10px; /* 内边距 */
  font-size: 16px; /* 字体大小 */
  font-weight: bold; /* 字体加粗 */
  border-top: 2px solid #ddd; /* 顶部边框 */
}
</style>
