<template>
  <div id="app">
    <div class="verDialog">
      <el-dialog
        v-if="showUpdateLog"
        :title="currentVersionTitle"
        :visible.sync="showUpdateLog"
      >
        <h3>{{ UpdateLogTitle }}</h3>
        <p>{{ UpdateLogDetail }}</p>
        <div slot="footer" class="flex">
          <div @click="markViewed" class="btn_ver">{{ viewedButton }}</div>
          <!-- <el-button type="primary" @click="markViewed">{{ viewedButton }}</el-button>
          <el-button @click="showUpdateLog=false">{{ cancelButton }}</el-button> -->
          <!-- <div @click="refreshToLatestVersion" class="btn_ver">使用最新版</div> -->
        </div>
      </el-dialog>
    </div>

    <div
      class="layout-container"
      v-if="!$route.meta || !$route.meta.hideHeader"
    >
    
      <div class="tree-tabs-container hidden-sm-and-down" v-if="$store.state.user.guanli >= 3">
        <el-tree
          :data="filteredTreeData"
          :props="treeProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div class="content-container">
        <el-tabs
          v-model="activeTab"
          @edit="handleTabsEdit"
          @tab-click="handleTabsClick"
          type="card"
          closable
        >
          <el-tab-pane
            v-for="tab in openTabs_c"
            :key="tab.name"
            :label="tab.label"
            :name="tab.name"
          >
            <keep-alive>
              <div class="router_app"><router-view></router-view></div>
            </keep-alive>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div v-else class="router_app"><router-view></router-view></div>

    <div
      class="header-right"
      v-show="!$route.meta.hideHeader"
      v-if="$store.state.user.username"
    >
      <el-dropdown trigger="click" style="display: block">
        <div class="user_header">
          <div class="useravatar">
            <el-image
              :src="
                $store.state.user.useravatar
                  ? $store.state.user.useravatar
                  : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
              "
            ></el-image>
          </div>
          <!-- <span class="el-dropdown-link username"
            >{{ $store.state.user.username
            }}<i class="el-icon-arrow-down el-icon--right"></i
          ></span> -->
          <el-dropdown-menu slot="dropdown" class="g_list">
            <el-dropdown-item @click.native="UseravatarShow"
              >编辑头像</el-dropdown-item
            >
            <el-dropdown-item @click.native="outUser"
              >退出账号</el-dropdown-item
            >
          </el-dropdown-menu>
        </div>
      </el-dropdown>
    </div>

    <el-dialog title="修改头像" :visible.sync="useravatarVisible">
      <div class="img_con_t">
        <div class="img_t">
          <div>旧图片</div>
          <div class="img_t_url">
            <el-image
              :src="
                $store.state.user.useravatar
                  ? $store.state.user.useravatar
                  : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
              "
            ></el-image>
          </div>
        </div>
        <div class="img_t">
          <div>新图片</div>
          <div class="img_t_url">
            <el-image :src="newuseravatarValue">
              <div slot="error" class="image-slot">
                点击下方默认图片,也可外链图片
              </div>
            </el-image>
          </div>
        </div>
      </div>
      <div class="img_con_list">
        <div
          v-for="(item, index) in img_list"
          :key="index"
          @click="img_click(item)"
          class="img_item"
        >
          <img :src="item" />
        </div>
      </div>
      <div class="img_btn">
        <el-input
          class="img_btn_input"
          v-model="newuseravatarValue"
          placeholder="请输入图片链接地址"
        ></el-input>
        <el-button @click="up_img" type="primary">确定</el-button>
      </div>
    </el-dialog>
    <!-- <keep-alive><router-view></router-view></keep-alive> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      treeData: [
        {
          label: "首页",
          path: "/index",
          children: [],
        },
        {
          label: "看板-中控台",
          path: "/ban",
          children: [],
        },
        {
          label: "看板面板",
          path: "/kanban",
          children: [],
        },
        {
          label: "图片库",
          path: "/imgku",
          children: [],
        },
        {
          label: "插件中心",
          path: "/plugins",
          children: [],
        },
        {
          label: "仓库看板",
          path: "/more/kucun",
          children: [],
        },
        {
          label: "汇总",
          path: "/production/summary",
          children: [],
        },
        {
          label: "生产单",
          path: "/production/list",
          children: [
            { label: "生产单列表", path: "/production/list" },
            { label: "用户管理", path: "/production/scdusers" },
          ],
        },
        {
          label: "更多",
          path: "",
          children: [
            { label: "经典语录", path: "/more/textlu", jia: true },
            { label: "图片压缩", path: "/more/imagecompression" },
            { label: "直播计划", path: "/more/liststream" },
            { label: "项目需求", path: "/more/projectrequirements" },
            { label: "需求列表", path: "/more/projectrequirementsList" },
            { label: "新建更新", path: "/more/addupdatelog", jia: true },
            { label: "查找重复列", path: "/more/inputcomparison" },
            { label: "退货转换", path: "/more/zhuanhuan" },
            { label: "仓库库存", path: "/more/beihuodan" },
            { label: "更新日志", path: "/more/updatelog" },
            { label: "关于本站", path: "/more/about" },
          ],
        },
      ],
      treeProps: {
        label: "label",
        children: "children",
      },
      // 新增用于标签页管理的数据
      activeTab: "",
      openTabs: [],
      tabIndex: 0,
      showUpdateLog: false, // 是否展示更新日志弹框
      UpdateLogTitle: "",
      UpdateLogDetail: "",
      currentVersionTitle: "",
      viewedVersion: null, // 上次已查看的版本号
      currentVersion: null, // 当前最新版本号
      viewedButtonArr: [
        // "看过了，表示一脸懵逼~",
        // "知道了，我已经升级到全宇宙最新版本了！",
        // "已阅，我现在感觉更加高级了！",
        // "别说了，我已经领悟到了真谛！",
        "知道了",
      ],
      cancelButtonArr: [
        "下次再看",
        // "先不看",
        // "让我静静",
        // "稍后再说，先去逛逛~",
      ],

      activeIndex: "1",
      activeIndex2: "1",
      userVvisible: false,
      useravatarVisible: false,
      useravatarValue: "",
      newuseravatarValue: "",
      img_true: false,
      img_list: [
        "https://p3-aio.ecombdimg.com/obj/ecom-shop-material/kpJWVrPA_m_aac69d3854da6b0aa005402fcde15417_sx_596579_www1080-1080",
        "https://p3-aio.ecombdimg.com/obj/ecom-shop-material/kpJWVrPA_m_2b63cddd76d3f93d3b32d12f96a158cc_sx_788288_www1080-1080",
        "https://p3-aio.ecombdimg.com/obj/ecom-shop-material/OahztkQo_m_6b6aff3b35a5e52bcc1b9752eb455653_sx_722950_www1024-1024",
        "https://p3-aio.ecombdimg.com/obj/ecom-shop-material/OahztkQo_m_3588111b8e43df43738e14590afc6c44_sx_602801_www1024-1024",
        "https://picx.zhimg.com/80/v2-233996ffdce3746ed7fa0421932f5575_720w.jpeg",
        "https://pic1.zhimg.com/80/v2-24d4fbdf3180093c5b50ca768560ed14_720w.jpeg",
        "https://picx.zhimg.com/80/v2-9d7c9cf30a958974e6342b5271b74106_720w.jpeg",
        "https://picx.zhimg.com/80/v2-767d5ebb40a116eb58c67b8cb7462fb5_720w.jpeg",
        "https://picx.zhimg.com/80/v2-81c2ef8bd89073ff848f9ff888325a2e_720w.jpeg",
        "https://picx.zhimg.com/80/v2-3320f2b3c375e405c507289694f77f94_720w.jpeg",
        "https://picx.zhimg.com/80/v2-d730184102c260dd2813733c9ac2888a_720w.jpeg",
        "https://picx.zhimg.com/80/v2-9a1022b2ebf040d2b98d53c12b8bff81_720w.jpeg",
        "https://pic1.zhimg.com/80/v2-82511b8d911b10207a0dc86cda5c98b1_720w.jpeg",
        "https://picx.zhimg.com/80/v2-8f1c177bdb0ce530b4dde839e08b3281_720w.jpeg",
        "https://picx.zhimg.com/80/v2-bbe04bbf29cb665f94e9faf0e70d40c5_720w.jpeg",
        "https://pica.zhimg.com/80/v2-7020d3572f6c028fa4af7cf5f9ea08de_720w.jpeg",
      ],
    };
  },
  async mounted() {
    
    setTimeout(() => {
      const currentRoute = this.$route.path;
      if (currentRoute.endsWith("/fuwu") || currentRoute === "/login") {
        return; // 不执行检查新版本的操作
        // }
        // if (currentRoute === "/fuwu" || currentRoute === "/login") {
        // return; // 不执行检查新版本的操作
      } else {
        // 从localStorage中获取上次已查看版本号
        this.viewedVersion = localStorage.getItem("viewedVersion");
        this.$axios.post("/api/more/updatelog/latest").then((res) => {
          this.currentVersion = res.data.version_number;
          this.currentVersionTitle = `发现新版本 V${res.data.version_number}`;

          // 解析版本号
          const currentVersionParts = this.currentVersion
            .split(".")
            .map(Number);
          const viewedVersionParts = this.viewedVersion
            ? this.viewedVersion.split(".").map(Number)
            : [0, 0, 0];

          // 比较版本号
          let isCurrentVersionNewer = false;
          for (let i = 0; i < currentVersionParts.length; i++) {
            if (currentVersionParts[i] > (viewedVersionParts[i] || 0)) {
              isCurrentVersionNewer = true;
              break;
            } else if (currentVersionParts[i] < (viewedVersionParts[i] || 0)) {
              break;
            }
          }

          // isCurrentVersionNewer = true;

          // 如果用户之前没有查看过更新日志或者当前版本号大于已查看的版本号，展示更新日志
          if (!this.viewedVersion || isCurrentVersionNewer) {
            this.showUpdateLog = true;
            this.UpdateLogTitle = res.data.title;
            let promise = Promise.resolve();
            for (let i = 0; i < res.data.detail.length; i++) {
              promise = promise.then(() => {
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(res.data.detail[i]);
                  }, 60); // 每隔一秒展示一次
                }).then((item) => {
                  this.UpdateLogDetail += res.data.detail[i];
                });
              });
            }
            this.viewedButton =
              this.viewedButtonArr[
                Math.floor(Math.random() * this.viewedButtonArr.length)
              ];
            this.cancelButton =
              this.cancelButtonArr[
                Math.floor(Math.random() * this.cancelButtonArr.length)
              ];
          }
        });
      }
    }, 1000);

    await this.$axios({
      method: "post", //请求方式
      url: "/users/verify",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        this.$store.commit("UpdateUserName", res.data.username);
        this.$store.commit("UpdateUserGuanli", Number(res.data.guanli));
        this.$store.commit(
          "UpdateUserAvatar",
          res.data.useravatar ? res.data.useravatar : ""
        );
        this.$store.commit("UpdateUserId", res.data.id ? res.data.id : "");
        let jia = localStorage.getItem("jia") ? localStorage.getItem("jia") : 0;
        if (jia != 0) {
          this.$store.commit("UpdateJia", jia);
        } else {
          localStorage.removeItem("jia");
        }
      })
      .catch((err) => {
        this.outUser();
      });

    const currentPath = this.$route.path;
    const currentTab =
      this.treeData.find((node) => node.path === currentPath) ||
      this.treeData
        .flatMap((node) => node.children)
        .find((child) => child.path === currentPath);

    if (currentTab) {
      this.openTabs.push({
        label: currentTab.label,
        name: currentTab.path,
      });
      this.activeTab = currentTab.path;
    } else {
      // currentPath /clothes/summary
      this.openTabs.push({
        name: currentPath,
      });
      this.activeTab = currentPath;
    }
  },
  methods: {
    closeTab(name) {
      // 从openTabs数组中找到要关闭的标签页索引
      let index = this.openTabs.findIndex((t) => t.name === name);
      if (index !== -1) {
        // 如果找到了，移除该标签页
        this.openTabs.splice(index, 1);
        // 如果当前激活的标签页就是要关闭的标签页，切换到其他标签页（例如第一个标签页）
        if (this.activeTab === name) {
          if (this.openTabs.length > 0) {
            this.activeTab = this.openTabs[0].name;
          } else {
            this.activeTab = "";
          }
        }
      }
    },
    // 点击跳转
    handleNodeClick(data) {
      if (data.path == "") {
        return;
      }
      if (this.$route.path === data.path) {
        return; // 如果当前路由已经是目标路由，直接返回，避免重复导航
      }

      let tab = this.openTabs.find((t) => t.name === data.path);

      if (tab) {
        this.activeTab = tab.name;
        // 检查当前路由是否与要导航的路由相同
        if (this.$route.path === data.path) {
          return; // 如果相同，则不进行导航
        }
      } else {
        this.openTabs.push({
          label: data.label,
          name: data.path,
        });
        this.activeTab = data.path;
      }

      // 使用 replace 来避免 NavigationDuplicated 错误
      this.$router.replace(data.path);
    },
    handleTabsClick(tab) {
      this.handleNodeClick({ path: tab.name });
    },
    handleTabsEdit(targetName, action) {
      if (action === "remove") {
        let tabs = this.openTabs;
        let activeName = this.activeTab;

        // 如果只有一个标签页，不进行关闭
        if (tabs.length === 1) {
          return;
        }

        // 找到要关闭的标签页的索引
        const targetIndex = tabs.findIndex((tab) => tab.name === targetName);

        // 更新 openTabs，去除掉要关闭的标签页
        this.openTabs = tabs.filter((tab) => tab.name !== targetName);

        // 如果关闭的是当前活跃的标签页
        if (activeName === targetName) {
          // 检查右侧是否还有标签页
          if (targetIndex < this.openTabs.length) {
            activeName = this.openTabs[targetIndex].name; // 跳转到右侧的下一个标签页
          } else {
            activeName = this.openTabs[this.openTabs.length - 1].name; // 如果没有右侧标签页，跳转到最后一个
          }
        }

        // 更新 activeTab 为新的活跃标签页
        this.activeTab = activeName;

        // 调用 handleNodeClick 跳转到新的活跃标签页
        this.handleNodeClick({ path: activeName });
      }
    },
    up_img() {
      if (this.useravatarValue == this.newuseravatarValue) {
        return;
      }
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_userimg",
        data: {
          newuseravatarValue: this.newuseravatarValue,
          id: this.$store.state.user.userid,
        },
      }).then((res) => {
        res = res.data;
        if (res.code == 200) {
          this.$store.commit("UpdateUserAvatar", this.newuseravatarValue);
          this.useravatarValue = this.newuseravatarValue;
          this.newuseravatarValue = "";
          this.useravatarVisible = false;
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
        }
      });
    },
    img_click(item) {
      this.newuseravatarValue = item;
    },
    UseravatarShow() {
      this.useravatarVisible = true;
    },
    outUser() {
      this.$store.commit("UpdateUserName", "");
      this.$store.commit("UpdateUserAvatar", "");
      this.commonApi.clearToken();
    },

    // 公告
    // 标记为已查看
    markViewed() {
      localStorage.setItem("viewedVersion", this.currentVersion);
      this.showUpdateLog = false;
    },
    refreshToLatestVersion() {
      localStorage.setItem("viewedVersion", this.currentVersion);
      this.showUpdateLog = false;
      window.location.reload(true);
    },
    handleClick(path) {
      // 处理左键点击
      // 这里可以添加任何需要的逻辑
    },
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    filteredTreeData() {
      if (this.$store.state.user.jia) {
        return this.treeData;
      } else {
        // 需要过滤掉 jia == true 的节点
        return this.treeData.map((node) => {
          if (node.children && node.children.length > 0) {
            return {
              ...node,
              children: node.children.filter((child) => !child.jia),
            };
          }
          return node;
        });
      }
    },
    // openTabs 不要有空白的
    openTabs_c() {
      return this.openTabs.filter((tab) => tab.name);
    },
  },
};
</script>

<style lang="less">
body,
html {
  height: 100%;
  cursor: url(/public/img/cursor/arrow.png) 0 0, auto !important;
}
.el-image,
.el-image__preview,
button,
span,
.el-menu-item,
.el-submenu__title,
.el-dropdown-menu__item,
.el-icon-circle-close {
  cursor: url(/public/img/cursor/hand.png) 0 0, auto !important;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  background: #f5f6f8;
  font-family: "Roboto", sans-serif, ui-sans-serif, system-ui, -apple-system,
    Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue,
    Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji,
    "PingFangSC-Regular", STHeiti, "Hiragino Sans GB", "Microsoft YaHei",
    "Helvetica Neue", Helvetica, Arial, sans-serif, "微软雅黑";
  font-feature-settings: normal;
}

#app {
  height: 100%;
}

.header {
  height: 60px;
  display: flex;
  background-color: #545c64;
  line-height: 60px;
}

.header-right {
  // padding: 0 20px;
  color: #fff;
  cursor: url(/public/img/cursor/hand.png) 0 0, auto;
  position: fixed;
  top: 5px;
  right: 10px;
  // background:#545c64;
  border: rgba(0, 0, 0, 0.08) 1px solid;
  // border-radius:0 0 0 10px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: #ccc 0 0 5px;
}
.user_header {
  display: flex;
}
.header-right .username {
  color: #fff;
}
.header-right .useravatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  // transform: translateY(15px);
  // margin-right: 10px;
  overflow: hidden;
}
.header-right .useravatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
:deep(.g_list li) {
  background: #fff;
}

.img_con_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.img_con_list .img_item {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  margin: 0 20px 20px 0;
  overflow: hidden;
  cursor: url(/public/img/cursor/hand.png) 0 0, auto;
}
.img_btn {
  display: flex;
}
.img_con_list .img_item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
:deep(.img_btn) {
  background: #fff;
}
.img_con_t {
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  margin-bottom: 50px;
}
.img_t {
  min-width: 42%;
  max-width: 42%;
  line-height: 20px;
  max-height: 200px;
  text-align: center;
}
.img_t_url {
  border: 1px dashed #ccc;
  padding: 8px;
}
.img_t img {
  max-height: 200px;
  width: calc(100%);
  height: calc(100%);
  border-radius: 10px;
  object-fit: contain;
}

h3 {
  padding: 0;
  margin: 0;
}

// 对话框样式
.verDialog .el-dialog__header {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px 10px 0 0;
  font-weight: bold;
  color: rgb(72, 72, 72) !important;
}
.verDialog .el-dialog__footer {
  border-radius: 0 0 10px 10px;
}
.verDialog .el-dialog__body {
  color: #fff;
  word-break: break-all;
  font-weight: bold;
  font-size: 20px;
}
// 对话框圆角
.verDialog .el-dialog__wrapper .el-dialog {
  border-radius: 10px;
  border: 1px solid #fff;
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0.3);
  background: none;
  background: rgba(255, 255, 255, 0.3);
}

.verDialog .el-dialog__wrapper {
  background-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.001);
  background: rgba(255, 255, 255, 0.2);
}

.btn_ver {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white; /* White Border */
  width: 100px;
  margin: 0 auto;
  border-radius: 10px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  height: 30px;
  cursor: url(/public/img/cursor/hand.png) 0 0, auto;
  transition: 0.3s;
}
.btn_ver:hover {
  background: rgba(255, 255, 255, 0.3);
}
</style>

<style scoped>
#app {
  height: 100%;
  background: #ffffff;
}
:deep(.header a) {
  text-decoration: none;
  color: inherit;
}
.layout-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.tree-tabs-container {
  width: 180px;
  min-width: 180px;
  box-sizing: border-box;
  border-right: 3px dashed #ccc;
}
.content-container {
  flex: 1;
}
.router_app {
  /* background:pink; */
  height: calc(100vh - 56px);
  box-sizing: border-box;
}
</style>
