
// 退出清理缓存
function clearToken(){
    let url = window.location.hash;
    if(url.indexOf("login") != -1){
        url = '';
    }else{
        localStorage.setItem("url",url);
    }
    localStorage.removeItem("token");
    window.location.href = window.location.origin + `#/login`;
}

// 获取url内容
function getParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");  
    //search,查询？后面的参数，并匹配正则
    var r = location.search.substr(1).match(reg);  
    if (r != null) return decodeURI(decodeURI(r[2])); 
}
//获取#号值
function GetRequest() {
    var str = location.href
    var num = str.indexOf("#");
    str = str.substr(num + 1);
    return str;
}

export default {
    clearToken,getParam,GetRequest
}